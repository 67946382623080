import {
  Image,
  Input,
  makeStyles,
  shorthands,
  Spinner,
} from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import { useMailboxContent } from '../../../provider/MailboxContentProvider';
import { Search24Regular } from '@fluentui/react-icons';
import { ActionEnum, ActivityType, DocumentType } from '../../../model/base';
import ReactDOM from 'react-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
  extractMatterName,
  formatUserActivityDate,
  openPDF,
  openWord,
} from '../../../helper/homeTabFns';

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const MainHomeTab = () => {
  const styles = useStyles();
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { userActivity } = useMailboxContent();
  const [popupWindow, setPopupWindow] = React.useState<Window | null>(null);
  const isMacos = navigator.userAgent.includes('Mac');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const {
    loadingSearch2,
    setSearchUserActivities,
    setLoadingSearch2,
    SearchUserActivity,
    searchUserActivities,
  } = useMailboxContent();
  const handleClick = (e: React.MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(e.target as Node)
    ) {
      setShowDropdown(false);
    }
  };
  const handleDisplayPopupWindow = (summary: string) => {
    // Close existing popup if open
    if (popupWindow && !popupWindow.closed) {
      popupWindow.close();
    }

    // Open new popup window
    const newWindow = window.open(
      '',
      'SummaryPopup',
      'width=800,height=600,resizable=yes,scrollbars=yes'
    );

    if (newWindow) {
      setPopupWindow(newWindow);

      // Write initial content to the new window
      newWindow.document.write(`
        <html>
          <head>
            <title>Matter History</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                line-height: 1.6;
                color: #333;
                max-width: 800px;
                margin: 0 auto;
                padding: 20px;
              }
              table {
                border-collapse: collapse;
                width: 100%;
                margin-bottom: 1rem;
              }
              th, td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
              pre {
                background-color: #f5f5f5;
                padding: 1em;
                overflow-x: auto;
              }
            </style>
          </head>
          <body>
            <div id="root"></div>
          </body>
        </html>
      `);

      // Render the ReactMarkdown in the popup
      ReactDOM.render(
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={{
            code({
              node,
              inline,
              className,
              children,
              ...props
            }: {
              node?: any;
              inline?: boolean;
              className?: string;
              children: React.ReactNode;
            }) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter
                  style={vscDarkPlus}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                  loading={() => <div>Loading syntax...</div>}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
            // Custom rendering for tables to ensure they're responsive
            table: ({ children, ...props }) => (
              <div style={{ overflowX: 'auto' }}>
                <table {...props}>{children}</table>
              </div>
            ),
          }}
        >
          {summary}
        </ReactMarkdown>,
        newWindow.document.getElementById('root')
      );

      // Add event listener to close React app when window closes
      newWindow.addEventListener('unload', () => {
        ReactDOM.unmountComponentAtNode(
          newWindow.document.getElementById('root')
        );
      });
    } else {
      console.error(
        'Failed to open popup window. It may have been blocked by the browser.'
      );
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    console.log(value, 'value ');
    setSearchTerm(value);
    if (!value || value.length == 0) {
      setShowDropdown(false);
      setSearchUserActivities([]);
    } else {
      setShowDropdown(true);
    }
  };
  //------------------------------------------
  useEffect(() => {
    return () => {
      console.log('Close Matter History');
      if (popupWindow && !popupWindow.closed) {
        popupWindow.close();
      }
    };
  }, [popupWindow]);
  useEffect(() => {
    if (debouncedSearchTerm) {
      setLoadingSearch2(true);
      SearchUserActivity({
        action: ActionEnum.SearchUserActivityRequest,
        message: debouncedSearchTerm,
      });
    }
  }, [debouncedSearchTerm]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target as Node) &&
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  //---------------------------------------------------------------
  if (!userActivity)
    return (
      <div className={styles.loadingCnt}>
        <Spinner size="small" />
      </div>
    );
  return (
    <div onClick={handleClick}>
      {userActivity && userActivity.length === 0 && (
        <div className={styles.noActCnt}>
          <div>
            <Image src="/assets/no_activity.png" alt="" />
          </div>
          <div className={styles.noActHead}>You have no recent activities</div>
        </div>
      )}
      {userActivity && userActivity.length > 0 && (
        <div className="actCnt">
          <div className={styles.actHeader}>
            <div className={styles.actHeaderTitle}>Recent</div>
            <Input
              placeholder="Search"
              className={styles.searchInput}
              contentAfter={
                <Search24Regular className={styles.searchInputIcon} />
              }
              value={searchTerm}
              ref={searchInputRef}
              onChange={handleInputChange}
              onClick={() => setShowDropdown(true)}
            />
            {loadingSearch2 === true && (
              <div className={styles.searchResults}>
                <div className={styles.loadingText}>
                  <Spinner size="small" />
                </div>
              </div>
            )}
            {showDropdown && loadingSearch2 === 'no-result' && (
              <div className={styles.searchResults}>
                <div className={styles.loadingText}>No Results </div>
              </div>
            )}
            {showDropdown &&
              searchUserActivities.length > 0 &&
              searchUserActivities.filter(itemA => {
                if (itemA.document_id) {
                  return !userActivity?.some(
                    itemB => itemB.document_id === itemA.document_id
                  );
                } else {
                  return !userActivity?.some(
                    itemB => itemB.conversation_id === itemA.conversation_id
                  );
                }
              }).length == 0 && (
                <div className={styles.searchResults}>
                  <div className={styles.loadingText}>No Results </div>
                </div>
              )}
            {showDropdown && loadingSearch2 === false && showDropdown && (
              <div className={styles.searchResults} ref={containerRef}>
                {searchUserActivities
                  .filter(itemA => {
                    if (itemA.document_id) {
                      return !userActivity.some(
                        itemB => itemB.document_id === itemA.document_id
                      );
                    } else {
                      return !userActivity.some(
                        itemB => itemB.conversation_id === itemA.conversation_id
                      );
                    }
                  })
                  .map(act => {
                    return (
                      <div
                        className={styles.tableRow}
                        key={act.id}
                        onClick={() => {
                          if (act.activity_type == ActivityType.matterReview) {
                            handleDisplayPopupWindow(act.matter_summary);
                          } else {
                            if (act.document_path) {
                              if (act.document_name?.includes('.pdf')) {
                                openPDF(act.document_path);
                              } else {
                                openWord(act.document_path, isMacos);
                              }
                            }
                          }
                        }}
                      >
                        <div className={styles.rowLeftSide}>
                          {getFileIcon(
                            act.activity_type as ActivityType,
                            DocumentType.Word,
                            styles
                          )}
                          <div>
                            <div className={styles.rowName}>
                              {act.matter_summary
                                ? extractMatterName(act.matter_summary)
                                : act.document_name}
                            </div>
                            <div className={styles.rowDate}>
                              {formatUserActivityDate(act.activity_date)}
                            </div>
                          </div>
                        </div>
                        <div className={styles.actType}>
                          {actTypeObject[act.activity_type]}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div className={styles.tableHeader}>
            <div className={styles.cellHeader}>Name</div>
            <div className={styles.cellHeader}>Activity</div>
          </div>
          {userActivity?.map(act => {
            return (
              <div
                className={styles.tableRow}
                key={act.id}
                onClick={() => {
                  if (act.activity_type == ActivityType.matterReview) {
                    handleDisplayPopupWindow(act.matter_summary);
                  } else {
                    if (act.document_path) {
                      if (act.document_name?.includes('.pdf')) {
                        openPDF(act.document_path);
                      } else {
                        openWord(act.document_path, isMacos);
                      }
                    }
                  }
                }}
              >
                <div className={styles.rowLeftSide}>
                  {getFileIcon(
                    act.activity_type as ActivityType,
                    DocumentType.Word,
                    styles
                  )}
                  <div>
                    <div className={styles.rowName}>
                      {act.matter_summary
                        ? extractMatterName(act.matter_summary)
                        : act.document_name}
                    </div>
                    <div className={styles.rowDate}>
                      {formatUserActivityDate(act.activity_date)}
                    </div>
                  </div>
                </div>
                <div className={styles.actType}>
                  {actTypeObject[act.activity_type]}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MainHomeTab;

const useStyles = makeStyles({
  loadingCnt: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '250px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  tableRow: {
    display: 'flex',
    ...shorthands.padding('6px'),
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '4px',
    marginRight: '4px',
    '&:hover': {
      backgroundColor: '#f0f0f0', // Change background color on hover
      cursor: 'pointer', // Change cursor to pointer on hover
    },
  },
  rowName: {
    fontFamily: 'Inter',
    fontSize: '11px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#3C3C3C',
    whiteSpace: 'nowrap' /* Prevents text from wrapping to a new line */,
    ...shorthands.overflow(
      'hidden'
    ) /* Ensures content that overflows the element is hidden */,
    textOverflow:
      'ellipsis' /* Adds '...' at the point where the text is cut off */,
    width: '160px',
  },
  rowImg: { width: '16px', height: '17px', marginTop: '6px' },
  actType: {
    fontFamily: 'Inter',
    fontSize: '11px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#3C3C3C',
  },
  rowDate: {
    fontFamily: 'Inter',
    fontSize: '11px',
    fontWeight: '400',
    lineHeight: '16px',
    color: '#6E6E6E',
  },
  rowLeftSide: {
    display: 'flex',
    ...shorthands.gap('6px'),
    alignItems: 'start',
  },
  noActCnt: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '150px',
    ...shorthands.gap('25px'),
  },
  noActHead: {
    fontFamily: 'Arial',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#777777',
  },
  actHeader: {
    display: 'flex',
    ...shorthands.padding('12px'),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tableHeader: {
    display: 'flex',
    ...shorthands.padding('12px'),
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#ECECEC',
    ...shorthands.borderRadius('3px'),
    marginLeft: '4px',
    marginRight: '4px',
  },
  cellHeader: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'left',
    color: '#000000',
  },
  actHeaderTitle: {
    fontFamily: 'Arial',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'left',
    color: '#3B3B3B',
  },
  searchInput: {
    width: '180px',
    height: '30px',
    ...shorthands.borderRadius('5px'),
    fontSize: '13px',
  },
  searchInputIcon: {
    width: '18px',
  },
  searchResults: {
    position: 'absolute',
    top: '110px',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    ...shorthands.border('1px', 'solid', '#ccc'),
    ...shorthands.borderRadius('8px'),
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    maxHeight: '60vh',
    overflowY: 'auto',
  },
  loadingText: {
    ...shorthands.padding('11px'),
    opacity: '80%',
    textAlign: 'center',
  },
});

const getFileIcon = (
  actType: ActivityType,
  docType: DocumentType,
  styles: Record<any, any>
) => {
  if (actType == ActivityType.matterReview) {
    return (
      <Image
        className={styles.rowImg}
        src={'assets/matterReview.png'}
        alt="Word Icon"
      />
    );
  } else {
    switch (docType) {
      case DocumentType.Word:
        return (
          <Image
            className={styles.rowImg}
            src={'assets/docx_16x16.png'}
            alt="Word Icon"
          />
        );

      case DocumentType.PDF:
        return (
          <Image
            className={styles.rowImg}
            src={'assets/pdf_16x16.png'}
            alt="PDF Icon"
          />
        );
    }
  }
};

const actTypeObject = {
  [ActivityType.docRevew]: 'Reviewing',
  [ActivityType.matterReview]: 'Matter History',
  [ActivityType.docDraft]: 'Draft',
  [ActivityType.docMemo]: 'Memo',
  [ActivityType.docProposal]: 'Proposal',
};
