import { Image, makeStyles, shorthands } from '@fluentui/react-components';
import React, { useMemo } from 'react';
import { DocumentType } from '../../../../model/relevantDocument';

const EmailPopUp = ({ linkedEmailDetail }) => {
  const emailTabstyles = useEmailStyles();
  const getFileIcon = (docType: DocumentType, styles: Record<any, any>) => {
    switch (docType) {
      case DocumentType.Word:
        return (
          <Image
            className={styles.fileTypeIcon}
            src={'assets/docx_16x16.png'}
            alt="Word Icon"
          />
        );

      case DocumentType.PDF:
        return (
          <Image
            className={styles.fileTypeIcon}
            src={'assets/pdf_16x16.png'}
            alt="PDF Icon"
          />
        );
      default:
        return docType;
    }
  };
  return (
    <div className={emailTabstyles.container}>
      <h2 className={emailTabstyles.subject}>{linkedEmailDetail?.subject}</h2>
      <div className={emailTabstyles.mb10}>
        <strong>From:</strong> {linkedEmailDetail?.sender}
      </div>
      <div className={emailTabstyles.mb10}>
        <strong>To:</strong> {linkedEmailDetail?.to}
      </div>
      {linkedEmailDetail?.cc && (
        <div className={emailTabstyles.mb10}>
          <strong>CC:</strong> {linkedEmailDetail?.cc}
        </div>
      )}
      <div className={emailTabstyles.mb10}>
        <strong>Received at:</strong>{' '}
        {new Date(linkedEmailDetail?.received_at).toLocaleString()}
      </div>
      <hr className={emailTabstyles.hr} />
      <div
        dangerouslySetInnerHTML={{ __html: linkedEmailDetail?.body }}
        className={emailTabstyles.mt10}
      />
      {linkedEmailDetail?.attachments &&
        linkedEmailDetail?.attachments.length > 0 && (
          <div className={emailTabstyles.attachmentsContainer}>
            <h3>Attachments:</h3>
            <ul className={emailTabstyles.attachmentList}>
              {linkedEmailDetail?.attachments.map(attachment => (
                <li
                  key={attachment.attachment_id}
                  className={emailTabstyles.attachmentItem}
                >
                  <a
                    href={attachment.document_path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={emailTabstyles.attachmentLink}
                  >
                    {getFileIcon(
                      attachment.document_type as DocumentType,
                      emailTabstyles
                    )}
                    <span style={{ paddingLeft: '5px' }}>
                      {attachment.document_name}
                    </span>
                  </a>
                  <div className={emailTabstyles.documentPreview}>
                    <p>
                      <strong>Document Preview:</strong>
                    </p>
                    <pre className={emailTabstyles.documentText}>
                      {attachment.document_text.substring(0, 500)}
                    </pre>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
    </div>
  );
};

export default EmailPopUp;

const useEmailStyles = makeStyles({
  container: {
    ...shorthands.padding('20px'),
    ...shorthands.borderRadius('5px'),
    maxWidth: '600px',
    ...shorthands.margin('20px auto'),
    fontFamily: 'Arial, sans-serif',
  },
  subject: {
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  mt10: {
    marginTop: '10px',
  },
  mb10: {
    marginBottom: '10px',
  },
  hr: {
    ...shorthands.border('1px solid #ddd'),
  },
  attachmentsContainer: {
    marginTop: '20px',
  },
  attachmentList: {
    ...shorthands.padding(0),
  },
  attachmentItem: {
    marginBottom: '10px',
  },
  attachmentLink: {
    color: '#1a0dab',
    ...shorthands.textDecoration('none'),
  },
  attachmentIcon: {
    verticalAlign: 'middle',
    marginRight: '8px',
  },
  documentPreview: {
    marginTop: '10px',
  },
  documentText: {
    whiteSpace: 'pre-wrap',
    backgroundColor: '#f4f4f4',
    ...shorthands.padding('10px'),
    ...shorthands.borderRadius('5px'),
  },
  fileTypeIcon: {
    width: '20px',
    height: '20px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '5px',
  },
});
