import { Dispatch } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { EmailAttachment } from '../model/openEmail';
import { BlobServiceClient } from '@azure/storage-blob';
import { tokenHelper } from '../helper/tokenHelper';
import axios from 'axios';

export const getAzureBlobSaSToken = (blobPath: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    tokenHelper.getAccessToken(async token => {
      try {
        const response = await axios.get(
          `${process.env.BACKEND_URL}/blob-sas?blob_path=${blobPath}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const sas = response.data.sas;

        resolve(sas);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  });
};
const getAzureBlobClient = (): Promise<BlobServiceClient> => {
  return new Promise((resolve, reject) => {
    tokenHelper.getAccessToken(async token => {
      try {
        const response = await axios.get(
          `${process.env.BACKEND_URL}/sas-token`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const url = response.data.url;
        console.log(url);
        resolve(new BlobServiceClient(url));
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  });
};

const getUser = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    tokenHelper.getAccessToken(async token => {
      try {
        console.log('Acquired token:', token);
        const response = await axios.get(
          `${process.env.BACKEND_URL}/users/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log('User data:', response.data);
        resolve(response.data);
      } catch (error) {
        console.error('Error getting user:', error);
        reject(error);
      }
    });
  });
};

export function isReadMode(): boolean {
  try {
    if (Office.context.mailbox.item) {
      // Check if displayReplyForm exists (it's only available in read mode)
      return typeof Office.context.mailbox.item.displayReplyForm === 'function';
    }
    return false;
  } catch (error) {
    console.error('Error in isReadMode:', error);
    return false; // Default to compose mode if there's an error
  }
}

export const fetchAndSetMessageBody = (
  setter: Dispatch<React.SetStateAction<string>>
): void => {
  const message = Office.context.mailbox.item;
  message.body.getAsync(Office.CoercionType.Html, function (asyncResult) {
    if (asyncResult.status !== Office.AsyncResultStatus.Succeeded) {
      console.error('Failed to get body:', asyncResult.error.message);
    } else {
      const bodyString = asyncResult.value;
      setter(bodyString);
    }
  });
};

export const fetchAndSetMessageText = (
  setter: Dispatch<React.SetStateAction<string>>
): void => {
  const message = Office.context.mailbox.item;
  message.body.getAsync(Office.CoercionType.Text, function (asyncResult) {
    if (asyncResult.status !== Office.AsyncResultStatus.Succeeded) {
      console.error('Failed to get body text:', asyncResult.error.message);
    } else {
      const bodyText = asyncResult.value;
      setter(bodyText);
    }
  });
};

export const fetchAndSetMessageCategories = (
  setter: Dispatch<React.SetStateAction<string[]>>
): void => {
  const message = Office.context.mailbox.item;

  message.categories.getAsync(asyncResult => {
    if (asyncResult.status !== Office.AsyncResultStatus.Succeeded) {
      console.error('Failed to get categories:', asyncResult.error.message);
      return;
    }

    if (!asyncResult.value) {
      console.log('Mail item has no categories.');
      return;
    }

    const categories = asyncResult.value;

    categories.forEach(category => {
      setter(currentList => [...currentList, category.displayName]);
    });
  });
};
export const fetchAndSetAttachmentList = (
  setter: Dispatch<React.SetStateAction<EmailAttachment[]>>
): Promise<void> => {
  const message = Office.context.mailbox.item;

  if (message.attachments.length === 0) {
    console.log('Mail item has no attachments.');
    return Promise.resolve();
  }

  // filter out attachments with name .doc or .pdf
  const filteredAttachments = message.attachments.filter(
    attachment =>
      !attachment.name.includes('.') ||
      attachment.name.endsWith('.docx') ||
      attachment.name.endsWith('.pdf') ||
      attachment.name.endsWith('.doc')
  );
  const attachmentPromises = filteredAttachments.map(attachment => {
    return new Promise<void>(async resolve => {
      const emailAttachment: EmailAttachment = {
        name: attachment.name,
        size: attachment.size.toString(),
        id: attachment.id,
      };
      setter(currentList => [...currentList, emailAttachment]);
      resolve();
    });
  });

  // Return a promise that resolves when all attachment promises have resolved.
  return Promise.all(attachmentPromises).then(() => undefined);
};

export function formatDateString(date: Date): string {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  // convert 24hr clock to 12hr clock format and set AM/PM
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const formattedHours = hours.toString().padStart(2, '0');

  return `${day}/${month}/${year}, ${formattedHours}:${minutes}${ampm}`;
}
