import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import App from './components/App';
import { AppProvider } from '../provider/appProvider';
const rootElement: HTMLElement = document.getElementById('container')!;

Office.onReady(async () => {
  const root = createRoot(rootElement);
  root.render(
      <FluentProvider theme={webLightTheme}>
          <AppProvider>
            <App />
          </AppProvider>
      </FluentProvider>
  );
});
