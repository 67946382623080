// frontend/provider/AttachmentListProvider.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { EmailAttachment } from '../model/openEmail';

interface AttachmentListContextProps {
  currentMessageAttachmentList: EmailAttachment[];
  setCurrentMessageAttachmentList: React.Dispatch<
    React.SetStateAction<EmailAttachment[]>
  >;
}

const AttachmentListContext = createContext<
  AttachmentListContextProps | undefined
>(undefined);

export const useAttachmentList = () => {
  const context = useContext(AttachmentListContext);
  if (!context) {
    throw new Error(
      'useAttachmentList must be used within an AttachmentListProvider'
    );
  }
  return context;
};

interface AttachmentListProviderProps {
  children: React.ReactNode;
}

export const AttachmentListProvider: React.FC<AttachmentListProviderProps> = ({
  children,
}) => {
  const [currentMessageAttachmentList, setCurrentMessageAttachmentList] =
    useState<EmailAttachment[]>([]);
  console.log(currentMessageAttachmentList, '>>>');
  return (
    <AttachmentListContext.Provider
      value={{ currentMessageAttachmentList, setCurrentMessageAttachmentList }}
    >
      {children}
    </AttachmentListContext.Provider>
  );
};
