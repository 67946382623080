import React, { createContext, useContext, useState, useEffect } from 'react';

const AppContext = createContext(null);

export const AppProvider = ({ children }) => {
  const [reloadApp, setReloadApp] = useState(false);
  const [seatStatus, setSeatStatus] = useState(null);
  const [isLogin, setIsLogin] = useState(true);

  useEffect(() => {
    const handleReloadEvent = () => {
      setReloadApp(true);
    };

    document.addEventListener('reloadAppEvent', handleReloadEvent);

    return () => {
      document.removeEventListener('reloadAppEvent', handleReloadEvent);
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        reloadApp,
        setReloadApp,
        seatStatus,
        setSeatStatus,
        isLogin,
        setIsLogin,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
