import React from 'react';
import {
  Button,
  Image,
  Link,
  makeStyles,
  shorthands,
} from '@fluentui/react-components';

const GetStart = ({
  setIsWelcomed,
}: {
  setIsWelcomed: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const styles = useStyles();
  return (
    <div className={styles.cnt}>
      <Image src="/assets/bigLogo.png"></Image>
      <div className={styles.head1}>Welcome to Qanooni!</div>
      <div className={styles.head2}>
        An AI System built by Lawyers for Lawyers
      </div>
      <div className={styles.listCnt}>
        <div className={styles.listItem}>
          <Image src="/assets/connect.png" width={29}></Image>
          <div>
            Connect to your firm’s Data, through our Outlook Plugin and easy DMS
            integrations
          </div>
        </div>
        <div className={styles.listItem}>
          <Image src="/assets/ai.png" width={29}></Image>
          <div>Use AI to summarize and stay on top of client Matters</div>
        </div>
        <div className={styles.listItem}>
          <Image src="/assets/qcounsel.png"></Image>
          <div>
            Use QCounsel as your legal search assistant across your firm’s
            information 
          </div>
        </div>
      </div>
      <Button
        className={styles.btn}
        onClick={() => {
          setIsWelcomed('true');
          localStorage.setItem('isWelcomed', 'true');
        }}
      >
        Get Started
      </Button>
      <div className={styles.footer}>
        <Image src="assets/right.png"></Image>
        <p>
          Your data is private and secure{' '}
          <Link href="#" className={styles.link}>
            Learn more
          </Link>
        </p>
      </div>
    </div>
  );
};

export default GetStart;
const useStyles = makeStyles({
  cnt: {
    paddingTop: '10vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    ...shorthands.gap('6px'),
    paddingRight: '1rem',
    paddingLeft: '1rem',
    position: 'relative',
  },

  head1: {
    fontFamily: 'Arial',
    fontSize: '25px',
    fontWeight: '700',
    lineHeight: '30px',
    textAlign: 'center',
  },
  head2: {
    fontFamily: 'Arial',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'center',
  },
  head3: {
    fontFamily: 'Arial',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'center',
  },
  listCnt: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('25px'),

    marginTop: '25px',
  },
  listItem: {
    display: 'flex',
    ...shorthands.gap('10px'),
    fontFamily: 'Arial',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    alignItems: 'center',
  },
  btn: {
    backgroundColor: '#04142D',
    fontFamily: 'Arial',
    color: 'white',
    fontSize: '15px',
    fontWeight: '400',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '55px',
    paddingRight: '55px',
    marginTop: '8px',
    ':hover': {
      backgroundColor: '#04142D',
      opacity: '0.8',
      color: 'white',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    bottom: '15px',
    width: '100%',
    color: '#5F5F5F',
    fontSize: '12px',
    ...shorthands.gap('5px'),
  },
  link: {
    color: '#202020',
    fontSize: '10px',
    ...shorthands.borderBottom('1px', 'solid', '#202020'),
  },
});
