import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeReact from 'rehype-react';
import remarkParse from 'remark-parse';
import remarkGfm from 'remark-gfm';
import remarkUnderline from '../../plugins/remarkUnderline';
import rehypeUnderline from '../../plugins/rehypeUnderline';
import { useState, Dispatch, SetStateAction } from 'react';
import {
  ChevronDown24Regular,
  ChevronUp24Regular,
} from '@fluentui/react-icons';
import { makeStyles, shorthands } from '@fluentui/react-components';
import { useMediaQuery } from 'react-responsive';

const splitMarkdown = (markdown: string) => {
  const section = markdown
    .split(/(?=\n### )/)
    .filter(section => section.trim());
  return section;
};

interface MarkdownRendererProps {
  markdown: string;
}

interface MarkdownSectionProps {
  section: string;
  index: number;
  activeIndexes: number[];
  setActiveIndexes: Dispatch<SetStateAction<number[]>>;
}

const MarkdownSection: React.FC<MarkdownSectionProps> = ({
  section,
  index,
  activeIndexes,
  setActiveIndexes,
}) => {
  const styles = useStyles();
  const isVisible = activeIndexes.includes(index);

  const toggleVisibility = () => {
    setActiveIndexes(
      isVisible ? activeIndexes.filter(i => i !== index) : [index]
    );
  };

  const headingMatch = section.match(/^(\n### |### )(.*)/);
  const heading = headingMatch ? headingMatch[0].trim() : null;

  const headingLevel = heading?.startsWith('### ') ? 'h2' : 'h3';

  const content = section
    .slice(headingMatch ? headingMatch[0].length : 0)
    .trim();

  const isDesktop1 = useMediaQuery({
    maxWidth: '1366px',
    maxHeight: '768px',
  });
  const isDesktop2 = useMediaQuery({
    maxHeight: '1920px',
    maxWidth: '1080px',
  });
  const isMacBook13 = useMediaQuery({
    maxWidth: '2560px',
    maxHeight: '1600px',
  });
  const isiPadPro = useMediaQuery({
    maxWidth: '2048px',
    maxHeight: '2732px',
  });

  const getHeadingContent = (device, level, text) => {
    const fontSizes = {
      Desktop1: { h2: 16, h3: 14, h4: 13 },
      Desktop2: { h2: 17, h3: 15, h4: 13 },
      MacBook13: { h2: 20, h3: 17, h4: 15 },
      iPadPro: { h2: 19, h3: 17, h4: 15 },
    };

    const fontWeights = {
      h2: 700,
      h3: 400,
      h4: 400,
    };

    const size = fontSizes[device][level];
    const weight = fontWeights[level];

    return (
      <p style={{ fontSize: `${size}px`, fontWeight: weight }}>
        {text.slice(3)}
      </p>
    );
  };

  const getDeviceType = () => {
    if (isDesktop1) return 'Desktop1';
    if (isDesktop2) return 'Desktop2';
    if (isMacBook13) return 'MacBook13';
    if (isiPadPro) return 'iPadPro';
    return null;
  };

  return (
    <div className={styles.section}>
      {heading && (
        <div className={styles.heading} onClick={toggleVisibility}>
          {getDeviceType() &&
            getHeadingContent(getDeviceType(), headingLevel, heading)}
          {isVisible ? (
            <ChevronUp24Regular className={styles.sortIcon} />
          ) : (
            <ChevronDown24Regular className={styles.sortIcon} />
          )}
        </div>
      )}
      {isVisible && (
        <div className={styles.tableContainer}>
          <ReactMarkdown
            remarkPlugins={[remarkParse, remarkGfm, remarkUnderline]}
            rehypePlugins={[rehypeUnderline, rehypeReact]}
            components={{
              table: ({ node, ...props }) => (
                <table className={styles.table} {...props} />
              ),
              th: ({ node, ...props }) => (
                <th className={styles.tableHeader} {...props} />
              ),
              td: ({ node, ...props }) => (
                <td className={styles.tableData} {...props} />
              ),
            }}
          >
            {content}
          </ReactMarkdown>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  section: {},
  heading: {
    cursor: 'pointer',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    ...shorthands.borderBottom('1px', 'solid', 'black'),
  },
  sortIcon: {
    width: '15px',
    marginLeft: '5px',
    color: 'black',
    paddingTop: '10px',
    paddingRight: '5px',
  },
  tableContainer: {
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '10px',
    tableLayout: 'fixed',
    wordWrap: 'break-word',
  },
  tableHeader: {
    ...shorthands.border('1px', 'solid', 'black'),
    ...shorthands.padding('10px'),
    textAlign: 'center',
  },
  tableData: {
    ...shorthands.border('1px', 'solid', '#ccc'),
    paddingBottom: '10px',
    paddingTop: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
});

export const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({
  markdown,
}) => {
  const sections = splitMarkdown(markdown);
  const [activeIndexes, setActiveIndexes] = useState([0, 1]);

  return (
    <div>
      {sections.map((section, index) => (
        <MarkdownSection
          key={index}
          section={section}
          index={index}
          activeIndexes={activeIndexes}
          setActiveIndexes={setActiveIndexes}
        />
      ))}
    </div>
  );
};
