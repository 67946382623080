import React, { useState } from 'react';

import {
  makeStyles,
  Input,
  MenuList,
  MenuItem,
  TabList,
  Tab,
  SelectTabEvent,
  SelectTabData,
  shorthands,
  Image,
  Button,
  Tooltip,
  Spinner,
} from '@fluentui/react-components';
import {
  Search24Regular,
  DocumentText24Regular,
  MailCheckmark24Regular,
  Link16Regular,
  Info16Regular,
} from '@fluentui/react-icons';

import { useMailboxContent } from '../../../provider/MailboxContentProvider';
import { ActionEnum } from '../../../model/base';

import { EmailTab } from './subTabs/EmailTab';
import { DocsTab } from './subTabs/DocsTab';
import { DocumentType } from '../../../model/relevantDocument';
import EmailPopUp from './subTabs/EmailPopUp';
import ReactDOM from 'react-dom';

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

enum TabValue {
  Email = 'Email',
  Docs = 'Docs',
}

const tabContent: Record<TabValue, { icon: JSX.Element; displayName: string }> =
  {
    [TabValue.Email]: {
      icon: <MailCheckmark24Regular />,
      displayName: 'Email',
    },
    [TabValue.Docs]: {
      icon: <DocumentText24Regular />,
      displayName: 'Docs',
    },
  };

export const EmailDocsTab: React.FC = () => {
  const styles = useStyles();
  const {
    sendSearchEmails,
    sendSearchAttachments,
    SearchEmailList,
    setSearchEmailList,
    linkedEmailList,
    SearchEmailAttachmentList,
    setSearchEmailAttachmentList,
    relevantDocuments,
    setLinkedEmailList,
    LinkUnlinkEmail,
    loadingSearch,
    setLoadingSearch,
    LinkedEmailDetail,
    linkedEmailDetail,
  } = useMailboxContent();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedKey, setSelectedKey] = useState<TabValue>(TabValue.Docs);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showApplyButton, setShowApplyButton] = useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const conversation_id = Office.context.mailbox.item.conversationId;
  const email_id = Office.context.mailbox.item.itemId;
  const [enablePop, setEnablePop] = useState(false);
  const [popupWindow, setPopupWindow] = React.useState<Window | null>(null);
  console.log(linkedEmailDetail);
  const handleTabChange = (
    _event: SelectTabEvent<HTMLElement>,
    data: SelectTabData
  ): void => {
    setSelectedKey(data.value as TabValue);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target as Node) &&
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleDisplayPopupWindow = () => {
    // Close existing popup if open
    if (popupWindow && !popupWindow.closed) {
      popupWindow.close();
    }

    // Open new popup window
    const newWindow = window.open(
      '',
      'SummaryPopup',
      'width=800,height=600,resizable=yes,scrollbars=yes'
    );

    if (newWindow) {
      setPopupWindow(newWindow);

      // Write initial content to the new window
      newWindow.document.write(`
        <html>
          <head>
            <title>Email Details</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                line-height: 1.6;
                color: #333;
                max-width: 800px;
                margin: 0 auto;
                padding: 20px;
              }
              table {
                border-collapse: collapse;
                width: 100%;
                margin-bottom: 1rem;
              }
              th, td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
              pre {
                background-color: #f5f5f5;
                padding: 1em;
                overflow-x: auto;
              }
            </style>
          </head>
          <body>
            <div id="root"></div>
          </body>
        </html>
      `);

      // Render the ReactMarkdown in the popup
      ReactDOM.render(
        <div>
          <EmailPopUp linkedEmailDetail={linkedEmailDetail} />
        </div>,
        newWindow.document.getElementById('root')
      );

      // Add event listener to close React app when window closes
      newWindow.addEventListener('unload', () => {
        ReactDOM.unmountComponentAtNode(
          newWindow.document.getElementById('root')
        );
      });
    } else {
      console.error(
        'Failed to open popup window. It may have been blocked by the browser.'
      );
    }
  };

  React.useEffect(() => {
    if (debouncedSearchTerm) {
      setLoadingSearch(true);
      if (selectedKey == TabValue.Email) {
        sendSearchEmails({
          action: ActionEnum.SearchEmailRequest,
          conversationId: conversation_id,
          message: debouncedSearchTerm,
          email_id: email_id,
        });
      }
      if (selectedKey == TabValue.Docs) {
        sendSearchAttachments({
          action: ActionEnum.SearchEmailAttachmentRequest,
          conversationId: conversation_id,
          message: debouncedSearchTerm,
          email_id: email_id,
        });
      }
    }
  }, [debouncedSearchTerm, selectedKey]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    console.log(value, 'value ');
    setSearchTerm(value);
    if (!value || value.length == 0) {
      setShowDropdown(false);
      setSearchEmailList([]);
      setSearchEmailAttachmentList([]);
    } else {
      setShowDropdown(true);
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(e.target as Node)
    ) {
      setShowDropdown(false);
    }
  };
  const getFileIcon = (docType: DocumentType, styles: Record<any, any>) => {
    switch (docType) {
      case DocumentType.Word:
        return (
          <Image
            className={styles.fileTypeIcon}
            src={'assets/docx_16x16.png'}
            alt="Word Icon"
          />
        );

      case DocumentType.PDF:
        return (
          <Image
            className={styles.fileTypeIcon}
            src={'assets/pdf_16x16.png'}
            alt="PDF Icon"
          />
        );

      case DocumentType.Excel:
        return (
          <Image
            className={styles.fileTypeIcon}
            src={'assets/xlsx_16x16.png'}
            alt="Excel Icon"
          />
        );

      case DocumentType.PowerPoint:
        return (
          <Image
            className={styles.fileTypeIcon}
            src={'assets/pptx_16x16.png'}
            alt="PowerPoint Icon"
          />
        );

      default:
        return docType;
    }
  };
  React.useEffect(() => {
    if (linkedEmailDetail && enablePop) {
      handleDisplayPopupWindow();
    }
  }, [linkedEmailDetail, enablePop]);
  return (
    <>
      <div className={styles.container} onClick={handleClick}>
        <div className={styles.tabContainer}>
          <TabList
            defaultSelectedValue={TabValue.Docs}
            size="small"
            className={styles.tabList}
            onTabSelect={(event, data) => handleTabChange(event, data)}
          >
            {Object.entries(tabContent).map(([key, { icon, displayName }]) => (
              <Tab
                icon={React.cloneElement(icon, {
                  className:
                    selectedKey === key
                      ? styles.selectedTabIcon
                      : styles.tabIcon,
                })}
                key={key}
                value={key as TabValue}
              >
                <div
                  className={`${selectedKey === key ? styles.selectedTabLabel : styles.tabLabel}`}
                >
                  {displayName}
                </div>
              </Tab>
            ))}
          </TabList>

          <Input
            placeholder="Search"
            className={styles.searchInput}
            contentBefore={
              <Search24Regular className={styles.searchInputIcon} />
            }
            value={searchTerm}
            ref={searchInputRef}
            onChange={handleInputChange}
            onClick={() => setShowDropdown(true)}
          />
          {loadingSearch === true && (
            <div className={styles.searchResults}>
              <div className={styles.loadingText}>
                <Spinner size="small" />
              </div>
            </div>
          )}
          {showDropdown && loadingSearch === 'no-result' && (
            <div className={styles.searchResults}>
              <div className={styles.loadingText}>NO RESULTS </div>
            </div>
          )}
          {showDropdown &&
            loadingSearch === false &&
            showDropdown &&
            (SearchEmailAttachmentList.length > 0 ||
              SearchEmailList.length > 0) && (
              <div ref={containerRef} className={styles.searchResults}>
                <MenuList>
                  {selectedKey == TabValue.Email &&
                    SearchEmailList.filter(
                      itemA =>
                        !linkedEmailList.some(
                          itemB =>
                            itemB.conversation_id === itemA.conversation_id
                        )
                    ).map(index => (
                      <MenuItem>
                        <div className={styles.resultItem}>
                          <div className={styles.linkIcon}>
                            <div
                              onClick={() => {
                                LinkedEmailDetail({
                                  action: ActionEnum.LinkedEmailDetailRequest,
                                  email_id: index.id,
                                });
                                setEnablePop(true);
                              }}
                            >
                              <Tooltip
                                content={'Info'} // Tooltip string
                                relationship="label" // Used for accessibility, indicates the element is a label
                              >
                                <Info16Regular />
                              </Tooltip>
                            </div>
                            <div
                              onClick={() => {
                                setLinkedEmailList(prev => {
                                  let arr = [
                                    ...prev,
                                    { ...index, linked: true, updated: true },
                                  ];
                                  return arr;
                                });
                                setShowApplyButton(true);
                              }}
                            >
                              <Tooltip
                                content={'Link Email'} // Tooltip string
                                relationship="label" // Used for accessibility, indicates the element is a label
                              >
                                <Link16Regular />
                              </Tooltip>
                            </div>
                          </div>

                          <span className={styles.resultTitle}>
                            {index.sender}
                          </span>
                          <span className={styles.resultDetails}>
                            {index.subject}
                          </span>
                          <span className={styles.dateSearch}>
                            {' '}
                            {new Intl.DateTimeFormat('en-GB', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: true,
                            }).format(new Date(index.received_at))}
                          </span>
                        </div>
                      </MenuItem>
                    ))}
                  {selectedKey == TabValue.Docs &&
                    SearchEmailAttachmentList.filter(
                      itemA =>
                        !relevantDocuments.some(
                          itemB =>
                            itemB.name === itemA.document_name &&
                            itemB.path == itemA.document_path
                        )
                    ).map(index => (
                      <MenuItem>
                        <div className={styles.resultItem}>
                          <span className={styles.resultTitle}>
                            {index.document_name}
                          </span>
                          <span className={styles.resultDetails}>
                            {getFileIcon(
                              index.document_type as DocumentType,
                              styles
                            )}
                          </span>
                          <span className={styles.dateSearch}>
                            {' '}
                            {new Intl.DateTimeFormat('en-GB', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: true,
                            }).format(new Date(index.created_at))}
                          </span>
                        </div>
                      </MenuItem>
                    ))}
                </MenuList>
              </div>
            )}
        </div>

        <div className={styles.tabContent}>
          {selectedKey === TabValue.Email && (
            <EmailTab
              showBtn={setShowApplyButton}
              setEnablePop={setEnablePop}
            />
          )}
          {selectedKey === TabValue.Docs && (
            <DocsTab showBtn={setShowApplyButton} />
          )}
          {showApplyButton && (
            <Button
              appearance="primary"
              size="medium"
              onClick={() => {
                LinkUnlinkEmail({
                  action: ActionEnum.LinkUnlinkEmailRequest,
                  host_email_id: email_id,
                  emails_to_link: linkedEmailList.filter(
                    l => l.linked === true && l.updated == true
                  ),
                  emails_to_unlink: linkedEmailList.filter(
                    l => l.linked === false && l.updated == true
                  ),
                });
                setLinkedEmailList(prev => {
                  let arr = [...prev];
                  return arr.filter(item => item.linked == true);
                });
                setShowApplyButton(false);
              }}
            >
              Apply
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles({
  container: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  tabContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '9px',
    paddingBottom: '12px',
    backgroundColor: '#fff',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: '#ccc',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.07)',
  },
  dateSearch: {
    width: '100%',
    textAlign: 'right',
    fontSize: 'smaller',
  },
  fileTypeIcon: {
    width: '20px',
    height: '20px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  tabList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tabIcon: {
    color: '#3B3B3B',
  },
  selectedTabIcon: {
    color: '#0387f5',
  },
  selectedTabLabel: {
    fontSize: '12px',
    fontWeight: '400',
    color: '#0387f5',
  },
  tabLabel: {
    fontSize: '12px',
    color: '#3B3B3B',
  },
  tabContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    overflowY: 'hidden',
    backgroundColor: 'white',
  },
  button: {
    marginTop: '10px',
  },
  searchInput: {
    width: '180px',
    height: '30px',
    ...shorthands.borderRadius('5px'),
    fontSize: '13px',
  },
  searchInputIcon: {
    width: '18px',
  },
  searchResults: {
    position: 'absolute',
    top: '110px',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    ...shorthands.border('1px', 'solid', '#ccc'),
    ...shorthands.borderRadius('8px'),
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    maxHeight: '60vh',
    overflowY: 'auto',
  },
  resultItem: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.padding('10px'),
    position: 'relative',
  },
  resultTitle: {
    fontWeight: 'bold',
    marginBottom: '5px',
    fontSize: '11px',
  },
  resultDetails: {
    color: '#555',
    fontSize: '12px',
  },
  linkIcon: {
    position: 'absolute',
    top: '10px',
    ...shorthands.gap('5px'),
    right: '5px',
    display: 'flex',
  },
  loadingText: {
    ...shorthands.padding('11px'),
    opacity: '80%',
    textAlign: 'center',
  },
});
