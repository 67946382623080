import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

// Import basic language support
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx';
import javascript from 'react-syntax-highlighter/dist/esm/languages/prism/javascript';
import typescript from 'react-syntax-highlighter/dist/esm/languages/prism/typescript';

// Register the languages
SyntaxHighlighter.registerLanguage('jsx', jsx);
SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('typescript', typescript);

import { makeStyles, Tooltip } from '@fluentui/react-components';

import { useMailboxContent } from '../../../provider/MailboxContentProvider';

import { SummaryContainer } from '../matterHistory/SummaryContainer';
import {
  ExpandUpRight24Regular,
  ThumbLike24Regular,
  ThumbDislike24Regular,
  ArrowClockwise24Regular,
} from '@fluentui/react-icons';

import { UserFeedbackRequest } from '../../../model/feedback';
import { ActionEnum } from '../../../model/base';
import { SummaryStatusEnum } from '../../../model/summary';
import { Spinner } from '@fluentui/react-components';
import ReactDOM from 'react-dom';
import { FeedbackModal } from 'qanoonisharedpackage';

export const MatterHistoryTab: React.FC = () => {
  const styles = useStyles();

  const {
    currentMessageSummary,
    featureRefId,
    traceId,
    socketRef,
    isSocketReady,
    isSummaryComplete,
    GetUserProcess,
    GetFeatureFlag,
    featureFlag,
  } = useMailboxContent();

  const showFeedbackButtons =
    currentMessageSummary?.status === SummaryStatusEnum.Completed;
  const [isLikeModalOpen, setIsLikeModalOpen] = React.useState(false);
  const [isDislikeModalOpen, setIsDislikeModalOpen] = React.useState(false);
  const [feedbackContent, setFeedbackContent] = React.useState<string>('');
  const dialogHandler = React.useRef(null);
  const [popupWindow, setPopupWindow] = React.useState<Window | null>(null);

  const [feature01, setFeature01] = React.useState<string>('');
  //const [feature02, setFeature02] = React.useState<string>('');

  useEffect(() => {
    if (isSocketReady) {
      GetFeatureFlag({
        action: ActionEnum.GetFeatureFlagRequest,
        feature_name: 'feature_01',
      });
      //GetFeatureFlag({
      //  action: ActionEnum.GetFeatureFlagRequest,
      //  feature_name: 'feature_02',
      //});
    }
  }, [isSocketReady]);

  useEffect(() => {
    if (featureFlag !== undefined) {
      console.log('Feature Flag Response:', featureFlag);
      if (featureFlag.feature_name == 'feature_01') {
        setFeature01(featureFlag.feature_value);
      }
      //if(featureFlag.feature_name == 'feature_02') {
      //  setFeature02(featureFlag.feature_value)
      //};
    }
  }, [featureFlag]);

  useEffect(() => {
    if (feature01) {
      console.log('feature01:', feature01);
    }
  }, [feature01]);

  //useEffect(() => {
  //  if (feature02) {
  //    console.log('feature02:', feature02)
  //  }
  //}, [feature02]);

  const handleDisplayPopupWindow = () => {
    if (
      currentMessageSummary === undefined ||
      currentMessageSummary.status === SummaryStatusEnum.InProgress
    )
      return;

    // Close existing popup if open
    if (popupWindow && !popupWindow.closed) {
      popupWindow.close();
    }

    // Open new popup window
    const newWindow = window.open(
      '',
      'SummaryPopup',
      'width=800,height=600,resizable=yes,scrollbars=yes'
    );

    if (newWindow) {
      setPopupWindow(newWindow);

      // Write initial content to the new window
      newWindow.document.write(`
        <html>
          <head>
            <title>Matter History</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                line-height: 1.6;
                color: #333;
                max-width: 800px;
                margin: 0 auto;
                padding: 20px;
              }
              table {
                border-collapse: collapse;
                width: 100%;
                margin-bottom: 1rem;
              }
              th, td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
              pre {
                background-color: #f5f5f5;
                padding: 1em;
                overflow-x: auto;
              }
            </style>
          </head>
          <body>
            <div id="root"></div>
          </body>
        </html>
      `);

      // Render the ReactMarkdown in the popup
      ReactDOM.render(
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={{
            code({
              node,
              inline,
              className,
              children,
              ...props
            }: {
              node?: any;
              inline?: boolean;
              className?: string;
              children: React.ReactNode;
            }) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter
                  style={vscDarkPlus}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                  loading={() => <div>Loading syntax...</div>}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
            // Custom rendering for tables to ensure they're responsive
            table: ({ children, ...props }) => (
              <div style={{ overflowX: 'auto' }}>
                <table {...props}>{children}</table>
              </div>
            ),
          }}
        >
          {currentMessageSummary.summaryParagraph}
        </ReactMarkdown>,
        newWindow.document.getElementById('root')
      );

      // Add event listener to close React app when window closes
      newWindow.addEventListener('unload', () => {
        ReactDOM.unmountComponentAtNode(
          newWindow.document.getElementById('root')
        );
      });
    } else {
      console.error(
        'Failed to open popup window. It may have been blocked by the browser.'
      );
    }
  };

  React.useEffect(() => {
    return () => {
      console.log('Close Matter History');
      if (popupWindow && !popupWindow.closed) {
        popupWindow.close();
      }
    };
  }, [popupWindow]);

  React.useEffect(() => {
    return () => {
      console.log('Close Matter History');
      if (dialogHandler.current) {
        dialogHandler.current.close();
      }
    };
  }, []);

  const handleRefresh = () => {
    if (isSocketReady) {
      console.log('recreate and refresh page');
      localStorage.setItem('messageData', 'recreate matter history');
    } else {
      console.log('refresh page');
    }
    window.location.reload();
  };

  const handleDisplayLikeModal = () => {
    setIsLikeModalOpen(true);
  };

  const handleDisplayDislikeModal = () => {
    setIsDislikeModalOpen(true);
  };

  const sendFeedbackGet = (feedbackType: 'thumbs_up' | 'thumbs_down') => {
    const request: UserFeedbackRequest = {
      action: ActionEnum.UserFeedbackRequest,
      feature_type: 'MatterHistory',
      feature_ref_id: featureRefId,
      feedback: feedbackType,
      trace_id: traceId,
      note: feedbackContent,
    };

    console.log('Sending feedback', request);
    if (socketRef && socketRef.readyState === WebSocket.OPEN) {
      socketRef.send(JSON.stringify(request));
    } else {
      console.error('WebSocket is not open. Unable to send feedback.');
    }

    // Close the modal after sending feedback
    setIsLikeModalOpen(false);
    setIsDislikeModalOpen(false);

    // Clear the feedback content
    setFeedbackContent('');
    console.log('Sent FeedbackGetRequest to server', request);
  };

  return (
    <>
      <div className={styles.popupButton}>
        <div className={styles.tabContentHeader}></div>
        {!isSummaryComplete && isSocketReady ? (
          <>
            <div className={styles.spinnerContainer}>
              <Spinner size="small" />
            </div>
          </>
        ) : null}
        {currentMessageSummary?.status === SummaryStatusEnum.Completed && (
          <>
            <Tooltip
              content="Pop up window for easier viewing"
              relationship="label"
            >
              <ExpandUpRight24Regular
                className={styles.popupIcon}
                onClick={handleDisplayPopupWindow}
              />
            </Tooltip>
            <Tooltip content="Thumbs up feedback" relationship="label">
              <ThumbLike24Regular
                className={styles.likeIcon}
                onClick={handleDisplayLikeModal}
              />
            </Tooltip>
            <Tooltip content="Thumbs down feedback" relationship="label">
              <ThumbDislike24Regular
                className={styles.dislikeIcon}
                onClick={handleDisplayDislikeModal}
              />
            </Tooltip>
            <Tooltip content="Refresh" relationship="label">
              <ArrowClockwise24Regular
                className={styles.refreshIcon}
                onClick={handleRefresh}
              />
            </Tooltip>
          </>
        )}
      </div>
      {currentMessageSummary?.status === SummaryStatusEnum.Completed ? (
        <div className={styles.container}>
          <SummaryContainer currentMessageSummary={currentMessageSummary} />
        </div>
      ) : null}
      <FeedbackModal
        isDislikeModalOpen={isDislikeModalOpen}
        isLikeModalOpen={isLikeModalOpen}
        setFeedbackContent={setFeedbackContent}
        setIsDislikeModalOpen={setIsDislikeModalOpen}
        setIsLikeModalOpen={setIsLikeModalOpen}
        sendFeedbackGet={sendFeedbackGet}
        feedbackContent={feedbackContent}
      />
    </>
  );
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  popupButton: {
    position: 'relative',
    top: 0,
    left: '0px',
  },
  popupIcon: {
    position: 'absolute',
    top: '10px',
    right: '104px',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  disabledpopupIcon: {
    cursor: 'not-allowed',
    position: 'absolute',
    top: '10px',
    right: '104px',
    marginBottom: '20px',
  },
  likeIcon: {
    position: 'absolute',
    top: '10px',
    right: '76px',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  dislikeIcon: {
    position: 'absolute',
    top: '10px',
    right: '48px',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  refreshIcon: {
    position: 'absolute',
    top: '10px',
    right: '20px',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  tabContentHeader: {
    height: '10px',
    fontSize: '21px',
    paddingLeft: '15px',
    fontWeight: 'bold',
    marginBottom: '30px',
  },
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '12px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  tabContentHeaderBottom: {
    height: '1px',
    width: '100%',
    backgroundColor: '#ccc',
  },
});
