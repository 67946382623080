import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const auth_source = 'outlook'; // 'outlook' or 'word'

class TokenHelper {
  callBackFunction = undefined;
  getAccessToken(
    callback: (q_token?: string) => void,
    clear_tokens?: boolean,
    callBackForLogin?: (q_token?: string) => void //not required I just need it when use token helper in Login Page
  ): void {
    //save this call back function because will need it
    const q_id = localStorage.getItem('q_id');
    if (!q_id) {
      const q_id = uuidv4();
      localStorage.setItem('q_id', q_id);
    }

    if (clear_tokens) {
      localStorage.removeItem('q_token');
      localStorage.removeItem('q_refresh');
    }

    const q_token = localStorage.getItem('q_token');

    const authCheck = async q_token => {
      try {
        const response = await axios.get(
          `${process.env.BACKEND_URL}/auth/check`,
          {
            headers: {
              Authorization: `Bearer ${q_token}`,
            },
          }
        );

        const q_refresh = localStorage.getItem('q_refresh') || null;
        if (response.data === null && q_refresh) {
          console.log('Using q_refresh token to get new tokens');
          const response = await axios.get(
            `${process.env.BACKEND_URL}/auth/refresh`,
            {
              headers: {
                Authorization: `Bearer ${q_refresh}`,
                q_id: q_id,
              },
            }
          );
          if (response.data) {
            if (response.data.q_refresh) {
              localStorage.setItem('q_refresh', response.data.q_refresh);
            }
            if (response.data.q_token) {
              localStorage.setItem('q_token', response.data.q_token);
            }
          }
          return response.data;
        }
        return response.data;
      } catch (error) {
        console.error('Error on authCheck:', error);
      }
    };

    authCheck(q_token)
      .then(authCheckRes => {
        if (authCheckRes) {
          //this.hideCustomAlert(isLogin, setIsLogin); No need for this line it is hide the login pop ui
          const q_token = localStorage.getItem('q_token');
          callback(q_token);
          if (callBackForLogin) {
            callBackForLogin(q_token);
          }
        } else {
          if (callBackForLogin) {
            this.callBackFunction = callBackForLogin;
            callBackForLogin(null);
          }
        }
      })
      .catch(err => {
        console.log('Failed on authCheck:', err);
      });
  }
  signinPopup = (callback: (q_token?: string) => void) => {
    const q_id = localStorage.getItem('q_id');
    const popupUrl =
      process.env.BACKEND_URL +
      '/signin?source=' +
      auth_source +
      '&q_id=' +
      q_id;
    const popupWindow = window.open(popupUrl, 'Popup', 'width=600,height=400');
    window.addEventListener(
      'message',
      function (event) {
        if (
          event.source !== popupWindow ||
          event.origin !== process.env.BACKEND_URL
        ) {
          return;
        }
        const data = event.data;
        if (data.q_token) {
          localStorage.setItem('q_token', data.q_token);
          if (data.q_refresh) {
            localStorage.setItem('q_refresh', data.q_refresh);
          }
          callback(data.q_token);
        }
      },
      false
    );
  };
  //this the function run when user click login button I use it inside login page instead of create element and give it as before here
  onClickLogin = () => {
    this.signinPopup(q_token => {
      if (q_token) {
        if (this.callBackFunction) {
          this.callBackFunction(q_token);
        } else {
          location.reload();
        }
      } else {
        console.error('Token not received');
      }
    });
  };
}

export const tokenHelper = new TokenHelper();
