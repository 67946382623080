export interface Summary {
  senderName: string;
  receivedAtDate: string;
  summaryParagraph: string;
  status: SummaryStatusEnum;
  errorMessage: string;
}

export enum SummaryStatusEnum {
  InProgress = 'InProgress',
  Completed = 'Completed',
}
