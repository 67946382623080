import React from 'react';

interface SpinnerProps {
  size?: number;
  borderWidth?: number;
  primaryColor?: string;
  secondaryColor?: string;
  speed?: string;
}

const Spinner: React.FC<SpinnerProps> = ({
  size = 10,
  borderWidth = 4,
  primaryColor = '#3498db',
  secondaryColor = '#ffffff',
  speed = '2s',
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          border: `${borderWidth}px solid ${secondaryColor}`,
          borderTop: `${borderWidth}px solid ${primaryColor}`,
          borderRadius: '50%',
          width: `${size}px`,
          height: `${size}px`,
          animation: `spin ${speed} linear infinite`,
        }}
      ></div>
      <style>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
};

export default Spinner;
