import {
  AttachmentResponse,
  EmailPerson,
  MatterHistoryResponse,
  MatterHistoryResponseChunk,
} from '../model/openEmail';
import { ActionEnum, BaseResponse } from '../model/base';
import { ChatEmailResponse, ChatEmailResponseChunk } from '../model/chat';
import { RelevantDocumentResponse } from '../model/relevantDocument';
import {
  GetReplyResponse,
  GetSearchAttachmentsResponse,
  GetSearchEmailsResponse,
  GetUnsentDocumentAttachmentsResponse,
  GetUserActivityResponse,
  GetUserProcessResponse,
  LinkedEmailDetailResponse,
  LinkedEmailListResponse,
  LinkUnlinkResponse,
  UpdateDocumentAttachmentResponse,
  GetFeatureFlagResponse,
  GetAccountMgmtResponse,
} from '../model/attachment';

export function isMatterHistoryResponse(
  message: BaseResponse
): message is MatterHistoryResponse {
  return (
    message.action === ActionEnum.MatterHistoryResponse && 'summary' in message
  );
}

export function isMatterHistoryResponseChunk(
  message: BaseResponse
): message is MatterHistoryResponseChunk {
  return (
    message.action === ActionEnum.MatterHistoryResponseChunk &&
    'summary' in message
  );
}

export function isAttachmentResponse(
  message: BaseResponse
): message is AttachmentResponse {
  return message.action === ActionEnum.AttachmentResponse;
}

export function isChatEmailResponse(
  message: BaseResponse
): message is ChatEmailResponse {
  return message.action === ActionEnum.ChatEmailResponse;
}

export function isChatEmailResponseChunk(
  message: BaseResponse
): message is ChatEmailResponseChunk {
  return message.action === ActionEnum.ChatEmailResponseChunk;
}

export function isRelevantDocumentResponse(
  message: BaseResponse
): message is RelevantDocumentResponse {
  return (
    message.action === ActionEnum.RelevantDocumentResponse &&
    'documents' in message
  );
}

export function isGetUnsentDocumentAttachmentsResponse(
  message: BaseResponse
): message is GetUnsentDocumentAttachmentsResponse {
  return message.action === ActionEnum.GetUnsentDocumentAttachmentsResponse;
}

export function isUpdateDocumentAttachmentResponse(
  message: BaseResponse
): message is UpdateDocumentAttachmentResponse {
  return message.action === ActionEnum.UpdateDocumentAttachmentResponse;
}

export function isGetReplyResponse(
  message: BaseResponse
): message is GetReplyResponse {
  return message.action === ActionEnum.GetReplyResponse;
}

export function isGetSearchResultEmailsResponse(
  message: BaseResponse
): message is GetSearchEmailsResponse {
  return message.action === ActionEnum.SearchEmailResponse;
}
export function isGetSearchResultEmailAttachmentsResponse(
  message: BaseResponse
): message is GetSearchAttachmentsResponse {
  return message.action === ActionEnum.SearchEmailAttachmentResponse;
}
export function isLinkUnlinkResponse(
  message: BaseResponse
): message is LinkUnlinkResponse {
  return message.action === ActionEnum.LinkUnlinkResponse;
}

export function isLinkedEmailListResponse(
  message: BaseResponse
): message is LinkedEmailListResponse {
  return message.action === ActionEnum.LinkedEmailListResponse;
}
export function isLinkedEmailDetailResponse(
  message: BaseResponse
): message is LinkedEmailDetailResponse {
  return message.action === ActionEnum.LinkedEmailDetailResponse;
}

export function isGetUserProcessResponse(
  message: BaseResponse
): message is GetUserProcessResponse {
  return message.action === ActionEnum.GetUserProcessResponse;
}
export function isGetUserActivityResponse(
  message: BaseResponse
): message is GetUserActivityResponse {
  return message.action === ActionEnum.GetUserActivityResponse;
}

export function isSearchUserActivityResponse(
  message: BaseResponse
): message is GetUserActivityResponse {
  return message.action === ActionEnum.SearchUserActivityResponse;
}

export function isGetFeatureFlagResponse(
  message: BaseResponse
): message is GetFeatureFlagResponse {
  return message.action === ActionEnum.GetFeatureFlagResponse;
}

export function isGetAccountMgmtResponse(
  message: BaseResponse
): message is GetAccountMgmtResponse {
  return message.action === ActionEnum.GetAccountMgmtResponse;
}

export function formatBytes(bytes, decimals = 2): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
