import { visit } from 'unist-util-visit';

export default function remarkUnderline() {
  return (tree: any) => {
    visit(tree, 'text', (node: any, index: any, parent: any) => {
      const UNDERLINE_REGEX = /~([^~]+)~/g;
      let matches;
      let children = [];

      let lastIndex = 0;
      while ((matches = UNDERLINE_REGEX.exec(node.value)) !== null) {
        if (matches.index > lastIndex) {
          children.push({
            type: 'text',
            value: node.value.slice(lastIndex, matches.index),
          });
        }

        children.push({
          type: 'element',
          tagName: 'ins',
          children: [{ type: 'text', value: matches[1] }],
        });

        lastIndex = matches.index + matches[0].length;
      }

      if (lastIndex < node.value.length) {
        children.push({ type: 'text', value: node.value.slice(lastIndex) });
      }

      if (children.length > 0) {
        parent.children.splice(index, 1, ...children);
      }
    });
  };
}
