export const getStylesForDevice = deviceType => {
    const stylesByDevice = {
        Desktop1: {
            textArea: { fontSize: '11px' },
            button: { fontSize: '11px' },
        },
        Desktop2: {
            textArea: { fontSize: '12px' },
            button: { fontSize: '12px' },
        },
        MacBook13: {
            textArea: { fontSize: '14px' },
            button: { fontSize: '14px' },
        },
        iPadPro: {
            textArea: { fontSize: '14px' },
            button: { fontSize: '14px' },
        },
    };

    return stylesByDevice[deviceType] || {};
};
