import React, { useEffect, useMemo, useState } from 'react';

import {
  Image,
  Link,
  makeStyles,
  shorthands,
  Spinner,
  Text,
} from '@fluentui/react-components';
import { useMailboxContent } from '../../../../provider/MailboxContentProvider';
import { getAzureBlobSaSToken } from '../../../../helper/mailbox';
import { ITooltipHostProps, TooltipHost } from '@fluentui/react';
import {
  DocumentType,
  RelevantDocument,
} from '../../../../model/relevantDocument';
import { DocumentDismiss24Regular } from '@fluentui/react-icons';

const getFileIcon = (docType: DocumentType, styles: Record<any, any>) => {
  switch (docType) {
    case DocumentType.Word:
      return (
        <Image
          className={styles.fileTypeIcon}
          src={'assets/docx_16x16.png'}
          alt="Word Icon"
        />
      );

    case DocumentType.PDF:
      return (
        <Image
          className={styles.fileTypeIcon}
          src={'assets/pdf_16x16.png'}
          alt="PDF Icon"
        />
      );

    case DocumentType.Excel:
    return (
      <Image
        className={styles.fileTypeIcon}
        src={'assets/xlsx_16x16.png'}
        alt="Excel Icon"
      />
    );

    case DocumentType.PowerPoint:
    return (
      <Image
        className={styles.fileTypeIcon}
        src={'assets/pptx_16x16.png'}
        alt="PowerPoint Icon"
      />
    );

  }
};
interface EmailTabProps {
  showBtn: React.Dispatch<React.SetStateAction<boolean>>;
}
export const DocsTab: React.FC<EmailTabProps> = ({ showBtn }) => {
  console.log(showBtn);
  const docsTabstyles = useDocsStyles();
  const { relevantDocuments, isRelevantDocumentsFetched, isSocketReady } =
    useMailboxContent();
  const isMacos = navigator.userAgent.includes('Mac');
  // Using ms-word url scheme
  const openWord = async (documentUrl: string) => {
    const containerPath = documentUrl.replace(
      `https://${process.env.QANOONI_DOCS_STORAGE_ACCOUNT_NAME}.blob.core.windows.net/documentattachments/`,
      ''
    );
    const sas = await getAzureBlobSaSToken(containerPath);
    const docUrlWithSas = `${documentUrl}?${sas}`;
    if (isMacos) {
      window.open(docUrlWithSas);
    } else {
      window.open(`ms-word:ofe|u|${docUrlWithSas}`);
    }
  };

  const openPDF = async (documentUrl: string) => {
    const containerPath = documentUrl.replace(
      `https://${process.env.QANOONI_DOCS_STORAGE_ACCOUNT_NAME}.blob.core.windows.net/documentattachments/`,
      ''
    );
    const sas = await getAzureBlobSaSToken(containerPath);
    const docUrlWithSas = `${documentUrl}?${sas}`;
    window.open(docUrlWithSas, '_blank');
  };

  const openExcel = async (documentUrl: string) => {
    const containerPath = documentUrl.replace(
      `https://${process.env.QANOONI_DOCS_STORAGE_ACCOUNT_NAME}.blob.core.windows.net/documentattachments/`,
      ''
    );
    const sas = await getAzureBlobSaSToken(containerPath);
    const docUrlWithSas = `${documentUrl}?${sas}`;
    if (isMacos) {
      window.open(docUrlWithSas);
    } else {
      window.open(`ms-excel:ofe|u|${docUrlWithSas}`);
    }
  };

  const openPowerPoint = async (documentUrl: string) => {
    const containerPath = documentUrl.replace(
      `https://${process.env.QANOONI_DOCS_STORAGE_ACCOUNT_NAME}.blob.core.windows.net/documentattachments/`,
      ''
    );
    const sas = await getAzureBlobSaSToken(containerPath);
    const docUrlWithSas = `${documentUrl}?${sas}`;
    if (isMacos) {
      window.open(docUrlWithSas);
    } else {
      window.open(`ms-powerpoint:ofe|u|${docUrlWithSas}`);
    }
  };


  const sortedDocuments = relevantDocuments.slice().sort((a, b) => {
    return (
      new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
    );
  });

  const tooltipProps: ITooltipHostProps['tooltipProps'] = {
    calloutProps: {
      styles: {
        beak: { backgroundColor: 'black' },
        calloutMain: {
          backgroundColor: 'black',
          padding: '8px',
          borderRadius: '4px',
        },
        container: { borderRadius: '4px' },
        root: { padding: 0, borderRadius: '4px' },
      },
    },
  };

  const DocumentLink = ({ doc, onClick }) => (
    <TooltipHost
      content={
        <div className={docsTabstyles.tooltipContent}>
          {doc.name}
        </div>
      }
      tooltipProps={tooltipProps}
      styles={{
        root: {
          display: 'inline-block',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
        },
      }}
    >
      <Link
        className={docsTabstyles.docsOpenLink}
        onClick={onClick}
      >
        <Text
          className={docsTabstyles.documentName}
          style={{ overflow: 'hidden' }}
        >
          {doc.name}
        </Text>
      </Link>
    </TooltipHost>
  );

  return (
    <div className={docsTabstyles.docsTabContainer}>
      {!isRelevantDocumentsFetched && isSocketReady ? (
        <Spinner labelPosition="below" label="Loading relevant documents..." />
      ) : relevantDocuments.length > 0 ? (
        <div className={docsTabstyles.tabContainer}>
          <div className={docsTabstyles.docsList}>
            {sortedDocuments.map((doc: RelevantDocument, index) => (
              <div key={index} className={docsTabstyles.docItem}>
                <div className={docsTabstyles.docIcon}>
                  {getFileIcon(doc.type, docsTabstyles)}
                  {doc.type === 'Word' ? (
                    <DocumentLink doc={doc} onClick={() => openWord(doc.path)} />
                  ) : doc.type === 'PDF' ? (
                    <DocumentLink doc={doc} onClick={() => openPDF(doc.path)} />
                  ) : doc.type === 'Excel' ? (
                    <DocumentLink doc={doc} onClick={() => openExcel(doc.path)} />
                  ) : doc.type === 'PowerPoint' ? (
                    <DocumentLink doc={doc} onClick={() => openPowerPoint(doc.path)} />
                  ) : null}
                  <div className={docsTabstyles.docDateSize}>
                    {new Date(doc.dateCreated).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={docsTabstyles.noDocsMessage}>
          <DocumentDismiss24Regular className={docsTabstyles.icon} />
          <p className={docsTabstyles.message}>
            There are no documents found associated with this Matter.
          </p>
        </div>
      )}
    </div>
  );
};

const useDocsStyles = makeStyles({
  docsTabContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '15px',
  },
  tabContainer: {
    width: '100%',
  },
  iconContainer: {
    marginBottom: '20px',
  },
  icon: {
    width: '100px',
    height: '100px',
    color: '#ccc',
  },
  message: {
    fontSize: '15px',
    color: '#666',
    ...shorthands.padding('10px'),
    textAlign: 'center',
  },
  docsTabHeader: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '20px',
    ...shorthands.padding('10px'),
    textAlign: 'center',
  },
  sortContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '10px',
    color: '#666',
    fontSize: '14px',
  },
  docsList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    height: '80vh',
    ...shorthands.gap('10px'),
  },
  docItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...shorthands.padding('10px'),
    ...shorthands.borderRadius('8px'),
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    ...shorthands.transition('background-color', '0.2s', 'ease'),
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
  docIcon: {
    marginRight: '6px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  docsOpenLink: {
    marginTop: '5px',
    width: '100%',
  },
  docName: {
    fontSize: '13px',
    fontWeight: '700',
    color: '#333',
  },
  docDateSize: {
    fontSize: '12px',
    fontWeight: '400',
    color: '#999',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '6px',
  },
  docActions: {
    display: 'flex',
    ...shorthands.gap('10px'),
    marginTop: '5px',
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    ...shorthands.padding('6px', '12px'),
    ...shorthands.borderRadius('6px'),
    backgroundColor: '#0078d4',
    color: '#fff',
    fontSize: '14px',
    cursor: 'pointer',
    ...shorthands.transition('background-color', '0.2s', 'ease'),
    '&:hover': {
      backgroundColor: '#005a9e',
    },
  },
  actionIcon: {
    marginRight: '6px',
    fontSize: '18px',
    cursor: 'pointer',
  },
  noDocsMessage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#666',
    fontSize: '16px',
    textAlign: 'center',
  },
  fileTypeIcon: {
    width: '20px',
    height: '20px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  documentName: {
    width: '100%',
    maxWidth: '190px',
    marginLeft: '6px',
    marginRight: '6px',
    textAlign: 'left',
    fontSize: '13px',
    color: '#444444',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    height: '12px',
  },
  tooltipContent: {
    color: 'white',
    backgroundColor: 'black',
  },
});
