import React, { useState } from 'react';
import { tokenHelper } from '../../../helper/tokenHelper';
import {
  Button,
  Image,
  Link,
  makeStyles,
  shorthands,
} from '@fluentui/react-components';
import GetStart from './GetStart';

const Login = () => {
  const styles = useStyles();
  const [isWelcomed, setIsWelcomed] = useState(
    localStorage.getItem('isWelcomed')
  );
  if (!isWelcomed) {
    return <GetStart setIsWelcomed={setIsWelcomed} />;
  }
  return (
    <div className={styles.cnt}>
      <Image src="/assets/bigLogo.png"></Image>
      <div className={styles.head1}>You are not signed in! </div>
      <div className={styles.head2}>Please sign in to continue.</div>
      <Button
        className={styles.btn}
        onClick={() => {
          tokenHelper.onClickLogin();
        }}
      >
        Sign In
      </Button>
      <div className={styles.footer}>
        <Image src="assets/right.png"></Image>
        <p>
          Your data is private and secure{' '}
          <Link href="#" className={styles.link}>
            Learn more
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
const useStyles = makeStyles({
  cnt: {
    paddingTop: '20vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    ...shorthands.gap('16px'),
  },
  head1: {
    fontFamily: 'Arial',
    fontSize: '25px',
    fontWeight: '700',
    lineHeight: '30px',
    textAlign: 'center',
  },
  head2: {
    fontFamily: 'Arial',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'center',
  },
  btn: {
    backgroundColor: '#04142D',
    fontFamily: 'Arial',
    color: 'white',
    fontSize: '15px',
    fontWeight: '400',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '55px',
    paddingRight: '55px',
    marginTop: '8px',
    ':hover': {
      backgroundColor: '#04142D',
      opacity: '0.8',
      color: 'white',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    bottom: '15px',
    width: '100%',
    color: '#5F5F5F',
    fontSize: '12px',
    ...shorthands.gap('5px'),
  },
  link: {
    color: '#202020',
    fontSize: '10px',
    ...shorthands.borderBottom('1px', 'solid', '#202020'),
  },
});
