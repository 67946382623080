import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { tokenHelper } from '../helper/tokenHelper'; // Import the TokenHelper
import { ActionEnum, BaseResponse, StatusEnum } from '../model/base';
import {
  EmailAttachment,
  EmailPerson,
  OpenEmailRequest,
} from '../model/openEmail';
import { Summary, SummaryStatusEnum } from '../model/summary';
import { ChatEmailRequest, ChatMessage, MessageRole } from '../model/chat';
import { RelevantDocument } from '../model/relevantDocument';

import {
  fetchAndSetAttachmentList,
  fetchAndSetMessageBody,
  fetchAndSetMessageText,
  fetchAndSetMessageCategories,
  formatDateString,
} from '../helper/mailbox';
import {
  isAttachmentResponse,
  isChatEmailResponse,
  isChatEmailResponseChunk,
  isGetSearchResultEmailAttachmentsResponse,
  isGetSearchResultEmailsResponse,
  isGetUserActivityResponse,
  isGetUserProcessResponse,
  isLinkedEmailDetailResponse,
  isLinkedEmailListResponse,
  isLinkUnlinkResponse,
  isMatterHistoryResponse,
  isMatterHistoryResponseChunk,
  isRelevantDocumentResponse,
  isSearchUserActivityResponse,
  isGetFeatureFlagResponse,
  isGetAccountMgmtResponse,
} from '../helper/misc';
import {
  isAnnotationTask,
  AnnotationTask,
  SendToAnnotationQueueRequest,
  ConvertPdfRequest,
} from '../model/annotation';
import { useAttachmentList } from './AttachmentListProvider';
import { BackgroundResponse } from '../model/background';
import { checkProcessStatus } from '../helper/backgroundprocess';
import { useAppContext } from './appProvider';
import { seatSocketMessages } from '../model/seat';
import {
  Email,
  GetSearchEmailsRequest,
  GetSearchAttachmentsRequest,
  LinkedEmailDetailRequest,
  LinkedEmailListRequest,
  LinkUnlinkEmailRequest,
  SearchedEmailAttachment,
  EmailWithAttachment,
  GetUserProcessRequest,
  UserOfflineProcess,
  AddMatterReviewActivityRequest,
  UserActivity,
  GetUserActivityRequest,
  SearchUserActivityRequest,
  GetFeatureFlagRequest,
  GetAccountMgmtRequest,
} from '../model/attachment';

const MailboxContentContext = createContext({
  removeChatMessage: (_messageId: string): void => {},
  currentMessageSummary: undefined as Summary | undefined,
  sendChatMessage: (_message: ChatMessage, _add_current: boolean): void => {},
  addAttachMessage: (_message: ChatMessage): void => {},
  sendAnnotationTask: (_task: SendToAnnotationQueueRequest): void => {},
  chatMessages: [] as ChatMessage[],
  relevantDocuments: [] as RelevantDocument[],
  isRelevantDocumentsFetched: false,
  annotationTasks: [] as AnnotationTask[],
  sendConvertPDF: (_task: ConvertPdfRequest): void => {},
  status: 'Indexing inbox...', // Add status to the context,
  errorMessage: '', // Add error message to the context
  LinkUnlinkEmail: (_message: LinkUnlinkEmailRequest): void => {},
  LinkedEmailList: (_message: LinkedEmailListRequest): void => {},
  LinkedEmailDetail: (_message: LinkedEmailDetailRequest): void => {},
  GetUserProcess: (_message: GetUserProcessRequest): void => {},
  GetUserActivity: (_message: GetUserActivityRequest): void => {},
  SearchUserActivity: (_message: SearchUserActivityRequest): void => {},
  sendSearchEmails: (_message: GetSearchEmailsRequest): void => {},
  sendSearchAttachments: (_message: GetSearchAttachmentsRequest): void => {},
  setSearchEmailList: undefined as any,
  setLinkedEmailList: undefined as any,
  setlinkedEmailDetail: undefined as any,
  setSearchEmailAttachmentList: undefined as any,
  linkUnlinkResult: undefined as string,
  linkedEmailList: [] as Email[],
  SearchEmailList: [] as Email[],
  linkedEmailDetail: null as EmailWithAttachment,
  userOfflineProcess: null as UserOfflineProcess,
  userActivity: [] as UserActivity[],
  setUserActivity: undefined as any,
  searchUserActivities: [] as UserActivity[],
  setSearchUserActivities: undefined as any,
  setUserOfflineProcess: undefined as any,
  SearchEmailAttachmentList: [] as SearchedEmailAttachment[],
  featureRefId: '', // Add this line
  traceId: '', // Add this line
  socketRef: null as WebSocket | null,
  isSocketReady: false,
  isSummaryComplete: false,
  loadingSearch: undefined,
  setLoadingSearch: undefined as any,
  loadingSearch2: undefined,
  setLoadingSearch2: undefined as any,
  GetFeatureFlag: (_message: GetFeatureFlagRequest): void => {},
  featureFlag: undefined,
  GetAccountMgmt: (_message: GetAccountMgmtRequest): void => {},
  accountMgmt: undefined,
});

export const useMailboxContent = () => useContext(MailboxContentContext);

interface MailboxContentProviderProps {
  children: React.ReactNode;
}

export const MailboxContentProvider: React.FC<MailboxContentProviderProps> = ({
  children,
}) => {
  // socket
  const [accesstoken, setToken] = useState<string | null>(null); // State to store the token

  const [loadingSearch, setLoadingSearch] = useState<any>(undefined);
  const [loadingSearch2, setLoadingSearch2] = useState<any>(undefined);
  const { setCurrentMessageAttachmentList: setAttachmentList } =
    useAttachmentList();
  const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
  const socketRef = useRef<WebSocket | null>(null);
  const [isSocketReady, setIsSocketReady] = useState(false);
  const [featureFlag, setFeatureFlag] = useState<any>(undefined);
  const [accountMgmt, setAccountMgmt] = useState<any>(undefined);
  // background process states
  const [status, setStatus] = useState<string>(''); // Add status state
  const [errorMessage, setErrorMessage] = useState<string>(''); // Add error message state
  const { seatStatus, setSeatStatus, setIsLogin, isLogin } = useAppContext();
  // Fetch and set the token when the component mounts
  useEffect(() => {
    tokenHelper.getAccessToken(
      _ => {},
      false,
      accesstoken => {
        setToken(accesstoken);
        if (accesstoken) {
          setIsLogin(true);
        } else {
          setIsLogin(false);
        }
      }
    );
  }, []);

  // current email
  const [currentMessageId, setCurrentMessageId] = useState<string>('');
  const [currentConversationId, setCurrentConversationId] =
    useState<string>('');
  const [currentMessageSubject, setCurrentMessageSubject] =
    useState<string>('');
  const [currentMessageSender, setCurrentMessageSender] =
    useState<EmailPerson>(undefined);
  const [currentMessageRecipientList, setCurrentMessageRecipientList] =
    useState<EmailPerson[]>([]);
  const [currentMessageCopyList, setCurrentMessageCopyList] = useState<
    EmailPerson[]
  >([]);
  const [currentMessageCategoryList, setCurrentMessageCategoryList] = useState<
    string[]
  >(['Contract Review']);
  const [currentMessageBody, setCurrentMessageBody] = useState<string>('');
  const [currentMessageText, setCurrentMessageText] = useState<string>('');
  const [currentMessageDateTimeReceived, setCurrentMessageDateTimeReceived] =
    useState<Date>(undefined);
  const [currentMessageDateTimeSent, setCurrentMessageDateTimeSent] =
    useState<Date>(undefined);
  const [currentMessageAttachmentList, setCurrentMessageAttachmentList] =
    useState<EmailAttachment[]>([]);
  const [isAttachmentListFetched, setIsAttachmentListFetched] = useState(false);
  const [generatedSummaryString, setGeneratedSummaryString] = useState<string>(
    'Creating summary with Qanooni AI...'
  );
  const [isSummaryComplete, setIsSummaryComplete] = useState<boolean>(false);
  const [currentMessageSummary, setCurrentMessageSummary] =
    useState<Summary>(undefined);
  const [threadId, setThreadId] = useState<string>('');
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [relevantDocuments, setRelevantDocuments] = useState<
    RelevantDocument[]
  >([]);
  const [isRelevantDocumentsFetched, setIsRelevantDocumentsFetched] =
    useState(false);
  const [annotationTasks, setAnnotationTasks] = useState<AnnotationTask[]>([]);

  const [linkUnlinkResult, setLinkUnlinkResult] = useState(null);
  const [linkedEmailList, setLinkedEmailList] = useState([]);
  const [userActivity, setUserActivity] = useState(undefined);

  const [userOfflineProcess, setUserOfflineProcess] = useState(null);

  const [SearchEmailList, setSearchEmailList] = useState([]);
  const [SearchEmailAttachmentList, setSearchEmailAttachmentList] = useState(
    []
  );
  const [linkedEmailDetail, setlinkedEmailDetail] = useState(null);
  const [featureRefId, setFeatureRefId] = useState<string>('');
  const [traceId, setTraceId] = useState<string>('');
  const [searchUserActivities, setSearchUserActivities] = useState([]);
  // get data from Office JS to prepare for websocket request
  useEffect(() => {
    if (accesstoken) {
      Office.onReady(() => {
        fetchAndSetMessageBody(setCurrentMessageBody);
        fetchAndSetMessageText(setCurrentMessageText);
        fetchAndSetMessageCategories(setCurrentMessageCategoryList);
        fetchAndSetAttachmentList(setCurrentMessageAttachmentList)
          .then(() => {
            setIsAttachmentListFetched(true);
          })
          .catch(error => console.error(error));

        const currentMessage = Office.context.mailbox.item;

        setCurrentMessageId(currentMessage.itemId);
        setCurrentConversationId(currentMessage.conversationId);
        setCurrentMessageSubject(currentMessage.subject);
        setCurrentMessageSender({
          email: currentMessage.sender.emailAddress,
          name: currentMessage.sender.displayName,
        });

        currentMessage.to.forEach(recipient => {
          setCurrentMessageRecipientList(currentList => [
            ...currentList,
            {
              email: recipient.emailAddress,
              name: recipient.displayName,
            },
          ]);
        });

        currentMessage.cc.forEach(recipient => {
          setCurrentMessageCopyList(currentList => [
            ...currentList,
            {
              email: recipient.emailAddress,
              name: recipient.displayName,
            },
          ]);
        });

        setCurrentMessageDateTimeReceived(currentMessage.dateTimeCreated);
        setCurrentMessageDateTimeSent(currentMessage.dateTimeCreated);
      });
    }
  }, [accesstoken]);

  // initialise websocket and set up a listener for incoming messages
  useEffect(() => {
    if (accesstoken) {
      const socketUrlWithToken = `${SOCKET_URL}?token=${accesstoken}`;
      try {
        socketRef.current = new WebSocket(socketUrlWithToken);
      } catch (e) {
        console.log(e);
        return;
      }

      socketRef.current.onopen = () => {
        console.log('WebSocket connection established.');
        setIsSocketReady(true);
      };

      socketRef.current.onmessage = (event: MessageEvent<string>) => {
        if (
          event.data == seatSocketMessages.no_seat ||
          event.data == seatSocketMessages.no_tandc
        ) {
          setSeatStatus(event.data);
          return;
        }
        const response: BaseResponse = JSON.parse(event.data);
        console.log('WebSocket message received:', response);

        if (response.action === ActionEnum.SignIn) {
          console.log('Sign In required');
          tokenHelper.getAccessToken(accesstoken => {
            setToken(accesstoken);
          }, true);
          if (isLogin) {
            setIsLogin(false);
          }
        }

        if (response.action === ActionEnum.RETRIEVAL_EMAIL) {
          const newResponse = response as BackgroundResponse;
          if (newResponse.process_id) {
            // Make an observer to check process status until this is completed
            const intervalId = setInterval(async () => {
              try {
                // Assuming you have an API to check the status of the process by its ID
                const processStatus = await checkProcessStatus(
                  newResponse.process_id
                );
                setStatus(processStatus.status);
                console.log(
                  `Background Process Status: ${processStatus.status}`
                );

                if (processStatus.status === StatusEnum.Completed) {
                  clearInterval(intervalId);
                }
              } catch (error) {
                console.error('Error checking process status:', error);
                clearInterval(intervalId);
              }
            }, 1000); // Check every 1 second
          } else {
            // Handle status update
            setStatus(response.status);
            console.log(`Background Process Status: ${response.status}`);
          }
        }

        if (response.status === StatusEnum.Error) {
          console.error('Error from server:', response.errorMessage);
          setIsSocketReady(false);
        }

        if (response.status === StatusEnum.Success) {
          if (
            isMatterHistoryResponseChunk(response) ||
            isMatterHistoryResponse(response)
          ) {
            if (isMatterHistoryResponse(response)) {
              setIsSummaryComplete(isMatterHistoryResponse(response));
            }

            const generatedSummary = response.summary;

            setGeneratedSummaryString(generatedSummary);
            setFeatureRefId(response.feature_ref_id);
            setTraceId(response.trace_id);
          }

          if (
            isChatEmailResponseChunk(response) ||
            isChatEmailResponse(response)
          ) {
            console.log('Received a chat message from ws:', response.response);

            setThreadId(response.thread_id);

            const isChunk = isChatEmailResponseChunk(response);

            setChatMessages(currentMessages => {
              const newMessage: ChatMessage = {
                id: uuidv4(),
                role: MessageRole.Assistant,
                message: response.response,
                isChunk,
                attached: '',
              };

              const shouldReplaceLastMessage =
                currentMessages.length > 0 &&
                currentMessages[currentMessages.length - 1].isChunk;

              if (shouldReplaceLastMessage) {
                return [...currentMessages.slice(0, -1), newMessage];
              } else {
                return [...currentMessages, newMessage];
              }
            });
          }

          if (isRelevantDocumentResponse(response)) {
            console.log(
              'Received relevant documents from ws:',
              response.documents
            );
            setRelevantDocuments(response.documents);
            setIsRelevantDocumentsFetched(true);
          }

          if (isAnnotationTask(response)) {
            console.log('Received an annotation task from ws:', response);
            setAnnotationTasks(currentTasks => [...currentTasks, response]);
          }

          if (isAttachmentResponse(response)) {
            console.log('Received the attachments from ws:', response);
            setAttachmentList(response.attachments);
          }

          if (isGetSearchResultEmailsResponse(response)) {
            console.log('Received the searched emails from ws:', response);
            // setAttachmentList(response.document_attachment)

            setSearchEmailList(response.email_list);
            if (response.email_list?.length === 0) {
              setLoadingSearch('no-result');
            } else {
              setLoadingSearch(false);
            }
          }

          if (isGetSearchResultEmailAttachmentsResponse(response)) {
            console.log(
              'Received the searched emails attachments from ws:',
              response
            );
            // setAttachmentList(response.document_attachment)
            setSearchEmailAttachmentList(response.attachment_list);
            if (response.attachment_list?.length === 0) {
              setLoadingSearch('no-result');
            } else {
              setLoadingSearch(false);
            }
          }

          if (isLinkUnlinkResponse(response)) {
            console.log('Received the Link/Unlink Result from ws:', response);
            setLinkUnlinkResult(response.result);
          }

          if (isLinkedEmailListResponse(response)) {
            console.log('Received the Linked Email List from ws:', response);
            let emails = response.email_list.map(e => ({ ...e, linked: true }));

            setLinkedEmailList(emails);
          }
          if (isLinkedEmailDetailResponse(response)) {
            console.log('Received the Linked Email List from ws:', response);
            let email_attachment = response.email_details;

            setlinkedEmailDetail(email_attachment);
          }
          if (isGetUserProcessResponse(response)) {
            console.log('Received the User Offline Process from ws:', response);
            let process = response.process;

            setUserOfflineProcess(process);
          }
          if (isGetUserActivityResponse(response)) {
            console.log('Received the User activities from ws:', response);
            let activity = response.activity;

            setUserActivity(activity);
          }
          if (isSearchUserActivityResponse(response)) {
            console.log(
              'Received the Search User activities from ws:',
              response
            );
            let activity = response.activity;
            if (activity) {
              setSearchUserActivities(activity);
              if (activity.length === 0) {
                setLoadingSearch2('no-result');
              } else {
                setLoadingSearch2(false);
              }
            }
          }
          if (isGetFeatureFlagResponse(response)) {
            console.log('Received GetFeatureFlagResponse:', response);
            setFeatureFlag(response);
          }
          if (isGetAccountMgmtResponse(response)) {
            console.log('Received isGetAccountMgmtResponse:', response);
            setAccountMgmt(response);
            if (response && response.account_response == 'SignOut') {
              console.log('SignOut');
              tokenHelper.getAccessToken(accesstoken => {
                setToken(accesstoken);
              }, true);
            }
          }
        }
      };

      socketRef.current.onclose = () => {
        console.log('WebSocket connection closed.');
        setIsSocketReady(false);
      };
      socketRef.current.onerror = (error: Event) => {
        console.log('WebSocket error:', error);
        setIsSocketReady(false);

        tokenHelper.getAccessToken(accesstoken => {
          setToken(accesstoken);
        }, true);
        setErrorMessage(
          'Failed to connect to the WebSocket server. Error 403: Forbidden'
        );
      };
    }
  }, [accesstoken]);

  // ping the websocket when we have all the data we need in place
  useEffect(() => {
    if (
      currentMessageSender &&
      currentMessageBody &&
      currentMessageSender &&
      currentConversationId &&
      currentMessageDateTimeReceived &&
      currentMessageDateTimeSent &&
      isSocketReady &&
      isAttachmentListFetched &&
      !isSummaryComplete
    ) {
      const updatedAttachmentList = currentMessageAttachmentList
        .filter(
          attachment =>
            !attachment.name.includes('.') ||
            attachment.name.endsWith('.docx') ||
            attachment.name.endsWith('.doc') ||
            attachment.name.endsWith('.pdf')
        )
        .map(attachment => ({
          ...attachment,
          encodedBody: '',
        }));

      const request: OpenEmailRequest = {
        action: ActionEnum.OpenEmailRequest,
        emailId: currentMessageId,
        subject: currentMessageSubject,
        emailFrom: currentMessageSender,
        emailTo: currentMessageRecipientList,
        cc: currentMessageCopyList,
        attachments: updatedAttachmentList,
        categories: currentMessageCategoryList,
        body: currentMessageBody,
        dateTimeReceived:
          currentMessageDateTimeReceived.toISOString().slice(0, -5) + 'Z',
        dateTimeSent:
          currentMessageDateTimeSent.toISOString().slice(0, -5) + 'Z',
        data: '',
        conversationId: currentConversationId,
      };

      console.log('Sending OpenEmailRequest to server', request);
      socketRef.current.send(JSON.stringify(request));
      GetUserProcess({
        action: ActionEnum.GetUserProcessRequest,
      });
      GetUserActivity({
        action: ActionEnum.GetUserActivityRequest,
      });
      // save user activity
      AddMatterReviewActivity({
        action: ActionEnum.AddMatterReviewActivityRequest,
        email_id: currentMessageId,
      });
    }
  }, [
    currentMessageId,
    currentMessageSubject,
    currentMessageSender,
    currentConversationId,
    currentMessageRecipientList,
    currentMessageCopyList,
    currentMessageCategoryList,
    currentMessageAttachmentList,
    isAttachmentListFetched,
    currentMessageBody,
    currentMessageDateTimeReceived,
    currentMessageDateTimeSent,
    isSocketReady,
  ]);

  // once everything is ready, set the summary in the context
  useEffect(() => {
    if (currentMessageSender && currentMessageDateTimeReceived) {
      const currentMessageSummary: Summary = {
        senderName: currentMessageSender.name,
        receivedAtDate: formatDateString(currentMessageDateTimeReceived),
        summaryParagraph: generatedSummaryString,
        errorMessage: '',
        status:
          generatedSummaryString === 'Creating summary with Qanooni AI...'
            ? SummaryStatusEnum.InProgress
            : SummaryStatusEnum.Completed,
      };

      // console.log('Current message summary', currentMessageSummary);
      setCurrentMessageSummary(currentMessageSummary);
    }
  }, [
    generatedSummaryString,
    currentMessageSender,
    currentMessageDateTimeReceived,
  ]);

  const addAttachMessage = (message: ChatMessage): void => {
    console.log('Adding attachment message:', message);
    setChatMessages(currentMessages => [...currentMessages, message]);
  };

  const sendChatMessage = (
    message: ChatMessage,
    add_current: boolean = true
  ): void => {
    console.log('Persisting user message:', message);
    const loadingIndicatorMessage: ChatMessage = {
      id: uuidv4(),
      role: MessageRole.Assistant,
      message: '...',
      isChunk: true,
      attached: '',
    };

    setChatMessages(currentMessages => [
      ...currentMessages,
      ...(add_current ? [message] : []), // Conditionally add the current message
      loadingIndicatorMessage,
    ]);

    const emailChatRequest: ChatEmailRequest = {
      action: ActionEnum.ChatEmailRequest,
      thread_id: threadId,
      messages: [message],
      open_email_body: currentMessageText,
      attachments: currentMessageAttachmentList,
    };

    console.log('Sending a chat request:', emailChatRequest);

    socketRef.current.send(JSON.stringify(emailChatRequest));
  };

  const sendAnnotationTask = (task: SendToAnnotationQueueRequest): void => {
    console.log('Sending an annotation task:', task);
    socketRef.current.send(JSON.stringify(task));
  };

  const sendConvertPDF = (task: ConvertPdfRequest): void => {
    console.log('Sending an PDF task:', task);
    socketRef.current.send(JSON.stringify(task));
  };

  const LinkUnlinkEmail = (request: LinkUnlinkEmailRequest): void => {
    console.log('Sending an Link Unlink Email Request :', request);
    socketRef.current.send(JSON.stringify(request));
  };

  const LinkedEmailList = (message: LinkedEmailListRequest): void => {
    console.log('Sending an Linked Email List Request :', message);
    socketRef.current.send(JSON.stringify(message));
  };

  const sendSearchEmails = (message: GetSearchEmailsRequest): void => {
    console.log('Sending an Search Email Request :', message);
    socketRef.current.send(JSON.stringify(message));
  };
  const sendSearchAttachments = (
    message: GetSearchAttachmentsRequest
  ): void => {
    console.log('Sending an Search Attachment Request :', message);
    socketRef.current.send(JSON.stringify(message));
  };

  const LinkedEmailDetail = (message: LinkedEmailDetailRequest): void => {
    console.log('Sending Linked Email Detail request:', message);
    socketRef.current.send(JSON.stringify(message));
  };
  const GetUserProcess = (message: GetUserProcessRequest): void => {
    console.log('Sending to Get user Offline Process request:', message);
    socketRef.current.send(JSON.stringify(message));
  };
  const GetUserActivity = (message: GetUserActivityRequest): void => {
    console.log('Sending to Get user activity request:', message);
    socketRef.current.send(JSON.stringify(message));
  };
  const SearchUserActivity = (message: SearchUserActivityRequest): void => {
    console.log('Sending to Get user activity request:', message);
    socketRef.current.send(JSON.stringify(message));
  };
  const AddMatterReviewActivity = (
    message: AddMatterReviewActivityRequest
  ): void => {
    console.log('Sending to add matter review user activity', message);
    socketRef.current.send(JSON.stringify(message));
  };
  const removeChatMessage = (messageId: string): void => {
    setChatMessages(currentMessages =>
      currentMessages.filter(message => message.id !== messageId)
    );
  };
  const GetFeatureFlag = (message: GetFeatureFlagRequest): void => {
    console.log('Sending to GetFeatureFlagRequest:', message);
    socketRef.current.send(JSON.stringify(message));
  };
  const GetAccountMgmt = (message: GetAccountMgmtRequest): void => {
    console.log('Sending to GetAccountMgmtRequest:', message);
    socketRef.current.send(JSON.stringify(message));
  };
  return (
    <MailboxContentContext.Provider
      value={{
        currentMessageSummary,
        sendChatMessage,
        addAttachMessage,
        sendAnnotationTask,
        chatMessages,
        relevantDocuments,
        isRelevantDocumentsFetched,
        annotationTasks,
        sendConvertPDF,
        LinkUnlinkEmail,
        LinkedEmailList,
        LinkedEmailDetail,
        sendSearchEmails,
        status,
        errorMessage,
        linkUnlinkResult,
        linkedEmailList,
        SearchEmailList,
        setSearchEmailList,
        setSearchEmailAttachmentList,
        SearchEmailAttachmentList,
        sendSearchAttachments,
        setLinkedEmailList,
        featureRefId,
        traceId,
        socketRef: socketRef.current,
        isSocketReady,
        isSummaryComplete,
        loadingSearch,
        setLoadingSearch,
        loadingSearch2,
        setLoadingSearch2,
        setlinkedEmailDetail,
        linkedEmailDetail,
        GetUserProcess,
        userOfflineProcess,
        setUserOfflineProcess,
        userActivity,
        setUserActivity,
        GetUserActivity,
        SearchUserActivity,
        setSearchUserActivities,
        searchUserActivities,
        removeChatMessage,
        GetFeatureFlag,
        featureFlag,
        GetAccountMgmt,
        accountMgmt,
      }}
    >
      {children}
    </MailboxContentContext.Provider>
  );
};
