import * as React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@fluentui/react-components';
import { TabContainer } from './TabContainer';
import { ComposePage } from './compose/ComposePage';
import { MailboxContentProvider } from '../../provider/MailboxContentProvider';
import { ComposeModeSocketProvider } from '../../provider/ComposeModeSocketProvider';
import { isReadMode } from '../../helper/mailbox';
import { ChatContainer } from './chat/ChatContainer';
import { useAppContext } from '../../provider/appProvider';
import { AttachmentListProvider } from '../../provider/AttachmentListProvider';
import NoSeat from './seats/NoSeat';
import NoTC from './seats/NoTC';
import { seatSocketMessages } from '../../model/seat';
import Login from './tab/Login';
import { ContactSalesPage, SubscriptionPage } from 'qanoonisharedpackage';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
    maxWidth: '100%',
    overflowX: 'hidden',
  },
});

const App: React.FC = () => {
  const styles = useStyles();

  const [officeReady, setOfficeReady] = useState(false);
  const {
    seatStatus,
    isLogin,
    is_subscribed,
    is_subscription_expired,
    isTrialExpired,
    setSessionId,
  } = useAppContext();
  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty(
      '--fontFamilyBase',
      "'Arial', Helvetica, sans-serif"
    );
  }, []);

  useEffect(() => {
    setSessionId(uuidv4());
    Office.onReady(() => {
      setOfficeReady(true);
    });
  }, []);

  const readMode = officeReady ? isReadMode() : false;
  if (seatStatus == seatSocketMessages.no_seat) return <NoSeat />;
  if (seatStatus == seatSocketMessages.no_tandc) return <NoTC />;
  if (!is_subscribed && localStorage.getItem('q_token'))
    if (isTrialExpired) {
      return <ContactSalesPage />;
    } else {
      return (
        <SubscriptionPage
          isExpired={is_subscription_expired}
          token={localStorage.getItem('q_token')}
        />
      );
    }

  return (
    <div className={styles.root}>
      {!isLogin && <Login />}
      {isLogin && officeReady && readMode ? (
        <AttachmentListProvider>
          <MailboxContentProvider>
            <TabContainer />
            <ChatContainer />
          </MailboxContentProvider>
        </AttachmentListProvider>
      ) : isLogin && officeReady ? (
        <ComposeModeSocketProvider>
          <ComposePage />
          <ChatContainer />
        </ComposeModeSocketProvider>
      ) : (
        isLogin && <div>Loading Office...</div>
      )}
    </div>
  );
};

export default App;
