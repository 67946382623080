import React, { useState, useEffect } from 'react';
import { useMailboxContent } from '../../provider/MailboxContentProvider';
import { Text, makeStyles, shorthands } from '@fluentui/react-components';

const useStyles = makeStyles({
  container: {
    ...shorthands.padding('5px 15px'),
    ...shorthands.border('1px', 'solid', '#d1d1d1'),
    ...shorthands.borderRadius('4px'),
    backgroundColor: 'white',
    boxShadow: '0 55px 3px rgba(0,0,0,0.3)',
    position: 'relative',
  },
  statusText: {
    color: '#666',
    fontSize: '11px',
  },
  header_switch: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
  },
  statusProcess: {
    animationName: {
      from: { opacity: 1 },
      to: { opacity: 0 },
    },
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    animationDirection: 'alternate',
  },
});

const BackgroundStatus: React.FC = () => {
  const styles = useStyles();
  const { status } = useMailboxContent();
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimating(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header_switch}>
          <Text className={styles.statusText}>
            {
              isAnimating ? 
                <strong className={styles.statusProcess}>
                  {status}
                </strong>
                :
                <></>
            }
          </Text>
        </div>
      </div>
    </>
  );
};

export default BackgroundStatus;
