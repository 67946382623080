import { makeStyles, shorthands } from '@fluentui/react-components';
import React, { useState } from 'react';
import { Image } from '@fluentui/react-components';
import { tokenHelper } from '../../../helper/tokenHelper';
import axios from 'axios';
import { useAppContext } from '../../../provider/appProvider';

type Props = {};

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', // Change to flex-start
    alignItems: 'center',
    backgroundColor: '#f7f8fa',
    ...shorthands.padding('20px', '20px', '0px'), // Add top padding
  },
  fileTypeIcon: {
    width: '150px',
    height: 'auto',
  },
  message: {
    fontSize: '22px', // Increased font size for better readability
    marginBottom: '30px',
    maxWidth: '500px',
    lineHeight: '1.6',
    color: '#333',
    textAlign: 'center',
  },
  button: {
    ...shorthands.border('none'),
    ...shorthands.outline('none'),
    ...shorthands.borderRadius('8px'), // More rounded corners
    ...shorthands.padding('14px', '28px'), // Increase padding for a larger button
    maxWidth: '320px', // Set a maximum width
    width: '95%', // Make it 90% of the parent container width
    ...shorthands.margin('0', 'auto'),
    fontSize: '16px', // Increased font size for readability
    fontWeight: '600', // Bold weight for prominence
    color: 'white',
    backgroundColor: '#004578', // A lighter, accessible blue
    cursor: 'pointer',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add a subtle shadow for a raised effect
    transitionProperty: 'background-color, opacity, transform',
    transitionDuration: '0.3s, 0.3s, 0.2s',
    transitionTimingFunction: 'ease',
    '&:hover': {
      backgroundColor: '#005a9e',
      opacity: 1,
      transform: 'translateY(-2px)', // Slight lift on hover
    },
    '&:disabled': {
      backgroundColor: '#d3d3d3',
      cursor: 'not-allowed',
      boxShadow: 'none', // No shadow when disabled
    },
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '35px',
  },
  checkbox: {
    marginRight: '10px',
  },
  link: {
    color: '#0078d4',
    ...shorthands.textDecoration('none'),
    '&:hover': {
      ...shorthands.textDecoration('underline'),
    },
  },
});

const NoTC: React.FC<Props> = () => {
  const styles = useStyles();
  const [isChecked, setIsChecked] = useState(false);
  const { setReloadApp, setSeatStatus } = useAppContext();

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = async () => {
    tokenHelper.getAccessToken(async token => {
      if (!token) return;
  
      console.log(token);
      try {
        const response = await axios.get(`${process.env.BACKEND_URL}/seat/acceptTC`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSeatStatus(null);
        setReloadApp(prev => !prev);
      } catch (error) {
        console.error('Submission failed:', error);
      }
    });
  };

  return (
    <div className={styles.container}>
      <Image
        className={styles.fileTypeIcon}
        src={'assets/original.png'}
        alt="Qanooni Logo"
      />
      <div className={styles.message}>
        Please read and accept the{' '}
        <a
          href="https://qanooni.ai/termsconditions"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          terms and conditions
        </a>{' '}
        and{' '}
        <a
          href="https://qanooni.ai/privacypolicy"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          privacy policy
        </a>.
      </div>
      <div className={styles.checkboxContainer}>
        <input
          type="checkbox"
          id="terms"
          className={styles.checkbox}
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="terms">
          I accept the{' '}
          <a
            href="https://qanooni.ai/termsconditions"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            terms and conditions
          </a>{' '}
          and{' '}
          <a
            href="https://qanooni.ai/privacypolicy"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            privacy policy
          </a>.
        </label>
      </div>
      <button
        className={styles.button}
        disabled={!isChecked}
        onClick={handleSubmit}
      >
        Accept and Continue
      </button>
    </div>
  );
};

export default NoTC;
