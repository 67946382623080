export enum StatusEnum {
  Success = 'success',
  Error = 'error',
  Completed = 'Completed',
}

export enum ActionEnum {
  OpenEmailRequest = 'OpenEmailRequest',
  UserFeedbackRequest = 'UserFeedbackRequest',
  SearchEmailRequest = 'SearchEmailRequest',
  SearchEmailAttachmentRequest = 'SearchEmailAttachmentRequest',
  'SearchEmailAttachmentResponse' = 'SearchEmailAttachmentResponse',
  SearchEmailResponse = 'SearchEmailResponse',
  LinkUnlinkEmailRequest = 'LinkUnlinkEmailRequest',
  LinkUnlinkResponse = 'LinkUnlinkResponse',
  LinkedEmailListRequest = 'LinkedEmailListRequest',
  LinkedEmailListResponse = 'LinkedEmailListResponse',
  LinkedEmailDetailRequest = 'LinkedEmailDetailRequest',
  LinkedEmailDetailResponse = 'LinkedEmailDetailResponse',
  GetUserProcessRequest = 'GetUserProcessRequest',
  GetUserProcessResponse = 'GetUserProcessResponse',
  GetUserActivityRequest = 'GetUserActivityRequest',
  GetUserActivityResponse = 'GetUserActivityResponse',
  SearchUserActivityResponse = 'SearchUserActivityResponse',
  SearchUserActivityRequest = 'SearchUserActivityRequest',
  SendEmailAttachment = 'SendEmailAttachment',
  GetReplyRequest = 'GetReplyRequest',
  GetReplyResponse = 'GetReplyResponse',
  MatterHistoryResponse = 'MatterHistoryResponse',
  MatterHistoryResponseChunk = 'MatterHistoryResponseChunk',

  ChatEmailRequest = 'ChatEmailRequest',
  ChatEmailResponse = 'ChatEmailResponse',
  ChatEmailResponseChunk = 'ChatEmailResponseChunk',

  RelevantDocumentResponse = 'RelevantDocumentResponse',

  GetUnsentDocumentAttachmentsRequest = 'GetUnsentDocumentAttachmentsRequest',
  GetUnsentDocumentAttachmentsResponse = 'GetUnsentDocumentAttachmentsResponse',
  UpdateDocumentAttachmentRequest = 'UpdateDocumentAttachmentRequest',
  UpdateDocumentAttachmentResponse = 'UpdateDocumentAttachmentResponse',
  AnnotationTask = 'AnnotationTask',
  SendToAnnotationQueueRequest = 'SendToAnnotationQueueRequest',
  ConvertPdfRequest = 'ConvertPdfRequest',
  RETRIEVAL_EMAIL = 'RETRIEVAL_EMAIL',
  CheckProcessStatus = 'CheckProcessStatus',
  AttachmentResponse = 'AttachmentResponse',
  SignIn = 'SignIn',
  AddMatterReviewActivityRequest = 'AddMatterReviewActivityRequest',
  GetFeatureFlagRequest = 'GetFeatureFlagRequest',
  GetFeatureFlagResponse = 'GetFeatureFlagResponse',
  GetAccountMgmtRequest = 'GetAccountMgmtRequest',
  GetAccountMgmtResponse = 'GetAccountMgmtResponse',
}

export interface BaseRequest {
  action: ActionEnum;
}

export interface BaseResponse {
  action: ActionEnum;
  status: StatusEnum;
  errorMessage?: string | null;
}

export interface MatterHistoryResponse extends BaseResponse {
  summary: string;
  feature_ref_id: string;
  trace_id: string;
}
export enum DocumentType {
  Word = 'Word',
  PDF = 'PDF',
  Excel = 'Excel',
  PowerPoint = 'PowerPoint',
}
export enum ActivityType {
  docRevew = 'document-review',
  docDraft = 'document-draft',
  matterReview = 'matter-review',
  docProposal = 'document-proposal',
  docMemo = 'document-memo',
}
