import axios from 'axios';
import { StatusEnum } from '../model/base';
import { tokenHelper } from '../helper/tokenHelper';

interface ProcessStatusResponse {
    status: StatusEnum;
    progress: number;
    date: string
}

export const checkProcessStatus = async (process_id: string): Promise<ProcessStatusResponse> => {
    return new Promise((resolve, reject) => {
        tokenHelper.getAccessToken(async token => {
            try {
                const response = await axios.get<ProcessStatusResponse>(
                    `${process.env.BACKEND_URL}/process-status?process_id=${process_id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                resolve(response.data);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        })
    })
};
