import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Input,
  InputProps,
  //makeStyles,
  mergeClasses,
  //shorthands,
  Tooltip,
  Textarea
} from '@fluentui/react-components';
import {
  Attach24Filled,
  ChatSparkle24Regular,
  ChevronDown16Filled,
  ChevronUp16Filled,
  Dismiss24Regular,
  Send24Filled,
  Copy24Regular,
  ArrowCounterclockwise24Regular,
  ThumbLike24Regular,
  ThumbDislike24Regular,
} from '@fluentui/react-icons';
import { UserFeedbackRequest } from '../../../model/feedback';
import { ActionEnum } from '../../../model/base';
import { useMediaQuery } from 'react-responsive';
//import { getStylesForDevice } from '../../../helper/getStylesForDevice';
import { useMailboxContent } from '../../../provider/MailboxContentProvider';
import { ChatMessage, MessageRole } from '../../../model/chat';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import * as pdfjsLib from 'pdfjs-dist';
import {Spinner, MarkdownChatRenderer, useSharedChatStyles, BatteryProgressBar} from 'qanoonisharedpackage';

interface ChatContainerProps {
}
const convertDate = timestamp => {
  const date = new Date(timestamp);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so +1
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
};

export const ChatContainer: React.FC<ChatContainerProps> = ({
}) => {
  const sharedStyles = useSharedChatStyles();
  //const styles = useStyles();
  const [value, setValue] = React.useState('');
  const [isChatVisible, setIsChatVisible] = React.useState(false);
  const [isChatOpen, setIsChatOpen] = React.useState(false);
  const [messagesContainerHeight, setMessagesContainerHeight] = useState('0');
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const [searchStatus, setSearchStatus] = useState('Searching...');
  const [failure, setfailure] = useState(false);
  const { addAttachMessage, isSocketReady, userOfflineProcess, featureRefId, traceId, socketRef, chatMessages, sendChatMessage, removeChatMessage } =
    useMailboxContent();
  const [feedbackContent, setFeedbackContent] = React.useState<string>('');
  const [isLikeModalOpen, setIsLikeModalOpen] = React.useState(false);
  const [isDislikeModalOpen, setIsDislikeModalOpen] = React.useState(false);
 const isDesktop1 = useMediaQuery({
  maxWidth: '1366px',
  maxHeight: '768px',
});
 const isDesktop2 = useMediaQuery({
  maxHeight: '1920px',
  maxWidth: '1080px',
});
 const isMacBook13 = useMediaQuery({
  maxWidth: '2560px',
  maxHeight: '1600px',
});
 const isiPadPro = useMediaQuery({
  maxWidth: '2048px',
  maxHeight: '2732px',
});
const deviceType = React.useMemo(() => {
  if (isDesktop1) return 'Desktop1';
  if (isDesktop2) return 'Desktop2';
  if (isMacBook13) return 'MacBook13';
  if (isiPadPro) return 'iPadPro';
  return null;
}, [isDesktop1, isDesktop2, isMacBook13, isiPadPro]);
const getStylesForDevice = deviceType => {
  const stylesByDevice = {
      Desktop1: {
          textArea: { fontSize: '11px' },
          button: { fontSize: '11px' },
      },
      Desktop2: {
          textArea: { fontSize: '12px' },
          button: { fontSize: '12px' },
      },
      MacBook13: {
          textArea: { fontSize: '14px' },
          button: { fontSize: '14px' },
      },
      iPadPro: {
          textArea: { fontSize: '14px' },
          button: { fontSize: '14px' },
      },
  };

  return stylesByDevice[deviceType] || {};
};
  const handleCopy = (message: string) => {
    const textarea = document.createElement('textarea');
    textarea.value = message;
    textarea.style.position = 'fixed';  // Avoid scrolling to bottom
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        console.log('Content copied to clipboard');
      } else {
        console.error('Failed to copy content');
      }
    } catch (err) {
      console.error('Failed to copy content: ', err);
    }

    document.body.removeChild(textarea);
  };

const handleRegenerate = async (messageId: string) => {
  console.log("regenerate pressed");
  setIsRegenerating(true);

  // Find the index of the message to regenerate
  const messageIndex = chatMessages.findIndex(msg => msg.id === messageId);
  if (messageIndex === -1) {
    console.error("Message not found");
    setIsRegenerating(false);
    return;
  }

  // Remove the message and all subsequent messages
  for (let i = chatMessages.length - 1; i >= messageIndex; i--) {
    removeChatMessage(chatMessages[i].id);
  }

    // Get the last user message before the removed message
    let lastUserMessage = "";
    for (let i = messageIndex - 1; i >= 0; i--) {
      if (chatMessages[i].role === MessageRole.User) {
        lastUserMessage = chatMessages[i].message;
        break;
      }
    }

    // Regenerate the response
    try {
      await sendChatMessage({
        id: uuidv4(),
        message: lastUserMessage,
        role: MessageRole.User,
        isChunk: false,
        attached: null,
      }, false);
    } catch (error) {
      console.error("Error regenerating message:", error);
    }

    setIsRegenerating(false);
  };

  const sendFeedbackGet = (feedbackType: 'thumbs_up' | 'thumbs_down') => {
    const request: UserFeedbackRequest = {
      action: ActionEnum.UserFeedbackRequest,
      feature_type: 'QCouncel chat',
      feature_ref_id: featureRefId,
      feedback: feedbackType,
      trace_id: traceId,
      note: feedbackContent,
    };

    console.log('Sending feedback', request);
    if (socketRef && socketRef.readyState === WebSocket.OPEN) {
      socketRef.send(JSON.stringify(request));
    } else {
      console.error('WebSocket is not open. Unable to send feedback.');
    }

    // Close the modal after sending feedback
    setIsLikeModalOpen(false);
    setIsDislikeModalOpen(false);

    // Clear the feedback content
    setFeedbackContent('');

    console.log('Sent FeedbackGetRequest to server', request);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
    feedbackType: 'thumbs_up' | 'thumbs_down'
  ) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendFeedbackGet(feedbackType);
    }
  };

  const handleThumbsUp = () => {
    setIsLikeModalOpen(true);
  };

  const handleThumbsDown = () => {
    setIsDislikeModalOpen(true);
  };

  const deviceStyles = React.useMemo(() => {
    return deviceType ? getStylesForDevice(deviceType) : {};
  }, [deviceType]);

  
  const inputContainerRef = useRef(null);
  const fileInputRef = useRef(null);
  useEffect(() => {
    const statuses = ['Searching...', 'Your documents', 'Public documents'];
    let index = 0;

    const interval = setInterval(() => {
      setSearchStatus(statuses[index]);
      index = (index + 1) % statuses.length; // Cycle through statuses
    }, 1000);

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  // Configure pdfjs to not use a worker
  pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      setLoading(true); // Show spinner when processing starts
      setfailure(false);

      try {
        if (fileExtension === 'txt') {
          // Handle .txt files directly
          const text = await selectedFile.text();
          console.log('attached text:', text);
          setFileContent(text);
        } else if (fileExtension === 'docx') {
          // Handle .docx files
          const arrayBuffer = await selectedFile.arrayBuffer();
          const zip = new PizZip(arrayBuffer);
          const doc = new Docxtemplater(zip);
          const text = doc.getFullText();
          console.log('attached text:', text);
          setFileContent(text);
        } else if (fileExtension === 'pdf') {
          // Handle .pdf files
          console.log('Processing PDF file');
          const arrayBuffer = await selectedFile.arrayBuffer();
          console.log('arrayBuffer:', arrayBuffer);

          // Convert ArrayBuffer to Uint8Array
          const uint8Array = new Uint8Array(arrayBuffer);

          // Load PDF with pdfjsLib
          const pdf = await pdfjsLib.getDocument({
            data: uint8Array,
            worker: null,
          }).promise;
          console.log('pdf:', pdf);
          let text = '';

          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const textContent = await page.getTextContent();
            textContent.items.forEach((item: any) => {
              if ('str' in item) {
                text += item.str + ' ';
              }
            });
          }
          console.log('attached text:', text);
          setFileContent(text);
        } else {
          // Handle unsupported file extensions
          console.log(`Unsupported file type: ${fileExtension}`);
          addAttachMessage({
            id: uuidv4(),
            message: `Failed to attach ${selectedFile.name}. Supported file types are PDF, DOCX, and TXT`,
            role: MessageRole.Attach,
            isChunk: false,
            attached: null,
          });
          return; // Exit the function if the file type is unsupported
        }
        addAttachMessage({
          id: uuidv4(),
          message: `Attached ${selectedFile.name}`,
          role: MessageRole.Attach,
          isChunk: false,
          attached: null,
        });
        console.log('attach message sent');
      } catch (error) {
        console.error('Error processing file:', error);
        setfailure(true);
        setLoading(false);
      } finally {
        setLoading(false); // Hide spinner when processing completes
      }
    }
  };

  useEffect(() => {
    if (inputContainerRef.current) {
      const height = isChatVisible
        ? `calc(100vh - ${inputContainerRef.current.clientHeight}px)`
        : '0';
      setMessagesContainerHeight(height);
    }
  }, [isChatVisible, inputContainerRef.current]);

  const [isRegenerating, setIsRegenerating] = useState(false);

  const onChange: InputProps['onChange'] = (_ev, data) => {
    setValue(data.value);
  };

  const onKeyDown: InputProps['onKeyDown'] = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onMessageSend();
    }
  };

  const onMessageSend = () => {
    const messageData = {
      message: value,
      attached: fileContent ? fileContent : null,
    };

    console.log('Sending a chat message:', messageData);

    sendChatMessage({
      id: uuidv4(),
      message: value,
      role: MessageRole.User,
      isChunk: false,
      attached: fileContent ? fileContent : null,
    }, true);

    setValue('');
    setFileContent(null);
    setIsChatVisible(true);
  };

  // Style for the outer white circle with shadow
  const outerCircleStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    bottom: '20px',
    right: '2px',
    backgroundColor: '#fff',
    borderRadius: '50%',
    width: '45px',
    height: '45px',
    boxShadow: '-2px 2px 4px rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
    cursor: 'pointer',
  };

  // Style for the inner black circle with chat icon
  const innerCircleStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
  };
  const process_value = React.useMemo(() => {
    if (userOfflineProcess) {
      if (
        userOfflineProcess.oldest_online == userOfflineProcess.oldest_ingested
      ) {
        return 1;
      } else {
        if (userOfflineProcess.ingest_total)
          return (
            userOfflineProcess.ingest_total / userOfflineProcess.email_total
          );
        else {
          return 0;
        }
      }
    } else {
      return null;
    }
  }, [userOfflineProcess]);
  const bottomRef = useRef(null);

  // Scroll to the bottom whenever a new message is added
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages]);

  return (
    <div className={sharedStyles.container}>
      <div
        className={sharedStyles.socketStatus}
        title={isSocketReady ? 'Connected' : 'Not Connected'}
      >
        <span
          style={{
            display: 'inline-block',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: isSocketReady ? 'green' : 'red',
          }}
        />
      </div>
      {!isChatOpen ? (
        <div
          style={outerCircleStyle}
          onClick={() => {
            setIsChatOpen(true);
            setIsChatVisible(true);
          }}
        >
          <div style={innerCircleStyle}>
            <ChatSparkle24Regular className={sharedStyles.openChatIcon} />
          </div>
        </div>
      ) : (
        <div>
          <div
            className={sharedStyles.messagesContainer}
            style={{
              height: messagesContainerHeight,
              display: isChatVisible ? 'flex' : 'none',
            }}
          >
            <div className={sharedStyles.messageContainerHeader}>
              <ChatSparkle24Regular
                className={sharedStyles.messageContainerHeaderLogo}
              />
              {userOfflineProcess && (
                <Tooltip
                  content={
                    process_value == 1
                      ? `Indexing emails is finished up to ${userOfflineProcess?.oldest_ingested && convertDate(userOfflineProcess?.oldest_ingested)}`
                      : userOfflineProcess?.oldest_ingested != null
                        ? `Indexing emails in progress, currently up to ${convertDate(userOfflineProcess?.oldest_ingested)}`
                        : 'Indexing emails pending'
                  } // Tooltip string
                  relationship="label" // Used for accessibility, indicates the element is a label
                >
                  <div className={sharedStyles.barCnt}>
                    <BatteryProgressBar
                      value={process_value}
                      width={'180px'}
                      height={5}
                    />
                  </div>
                </Tooltip>
              )}
              <Dismiss24Regular
                className={sharedStyles.messageContainerHeaderIcon}
                onClick={() => {
                  setIsChatVisible(false);
                  setIsChatOpen(false);
                }}
              />
            </div>
            <div className={sharedStyles.messageRenderContainer}>

            {isLikeModalOpen && (
        <div className={sharedStyles.modalOverlay}>
          <div className={sharedStyles.modalContent}>
            <h2>Please tell us what you like about it</h2>
            <Textarea
              className={sharedStyles.textArea}
              style={deviceStyles.textArea}
              placeholder="Write here ..."
              onChange={e => {
                setFeedbackContent(e.target.value);
              }}
              onKeyDown={e => handleKeyDown(e, 'thumbs_up')}
            ></Textarea>
            <div className={sharedStyles.modalActions}>
              <Button
                className={sharedStyles.notNowButton}
                style={deviceStyles.button}
                onClick={() => setIsLikeModalOpen(false)}
              >
                Not Now
              </Button>
              <Button
                className={sharedStyles.sendButton}
                style={deviceStyles.button}
                onClick={() => sendFeedbackGet('thumbs_up')}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      )}

      {isDislikeModalOpen && (
        <div className={sharedStyles.modalOverlay}>
          <div className={sharedStyles.modalContent}>
            <h2>Please tell us what is wrong that you don't like.</h2>
            <Textarea
              className={sharedStyles.textArea}
              style={deviceStyles.textArea}
              placeholder="Write here ..."
              onChange={e => {
                setFeedbackContent(e.target.value);
              }}
              onKeyDown={e => handleKeyDown(e, 'thumbs_down')}
            ></Textarea>
            <div className={sharedStyles.modalActions}>
              <Button
                className={sharedStyles.notNowButton}
                style={deviceStyles.button}
                onClick={() => setIsDislikeModalOpen(false)}
              >
                Not Now
              </Button>
              <Button
                className={sharedStyles.sendButton}
                style={deviceStyles.button}
                onClick={() => sendFeedbackGet('thumbs_down')}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      )}
                <div
                  key={'process_message'}
                  className={mergeClasses(
                    sharedStyles.message,
                    sharedStyles.assistantMessage
                  )}
                >
                  <div>
                  {process_value == 1
                  ? `Please note that your mailbox is fully indexed up to ${userOfflineProcess?.oldest_ingested && convertDate(userOfflineProcess?.oldest_ingested)}`
                  :userOfflineProcess?.oldest_ingested != null
                      ? `Please note that your mailbox is being indexed so responses will be limited going back to ${convertDate(userOfflineProcess.oldest_ingested)}`
                      : 'Indexing emails pending'}
                  </div>
                </div>
              {chatMessages.map((message: ChatMessage) => {
                const messageClass =
                  message.role === 'user'
                    ? sharedStyles.userMessage
                    : message.role === 'attach'
                      ? sharedStyles.attachMessage
                      : sharedStyles.assistantMessage;

                return (
                  <div
                    key={message.id}
                    className={message.role === 'user' ? sharedStyles.userMessageContainer : undefined}
                  >
                  <div className={mergeClasses(sharedStyles.message, messageClass)}>
                    {message.message === '...' ? (
                      <div>{searchStatus}</div>
                    ) : (
                      <MarkdownChatRenderer
                        markdown={message.message}
                      />
                    )}
                  </div>
              {message.role === 'assistant' && message.message !== '...' && (
                <div className={sharedStyles.actionBox}>
                  <Copy24Regular
                    className={sharedStyles.actionIcon}
                    onClick={() => handleCopy(message.message)}
                  />
                  <ArrowCounterclockwise24Regular
                    className={sharedStyles.actionIcon}
                    onClick={() => handleRegenerate(message.id)}
                  />
                  <ThumbLike24Regular
                    className={sharedStyles.actionIcon}
                    onClick={() => handleThumbsUp()}
                  />
                  <ThumbDislike24Regular
                    className={sharedStyles.actionIcon}
                    onClick={() => handleThumbsDown()}
                  />
                </div>
              )}
            </div>
          );
              })}
              <div ref={bottomRef}></div>
            </div>

          </div>

          <div className={sharedStyles.messageInputContainer} ref={inputContainerRef}>
            <div className={sharedStyles.messageInputTopBar}>
              <ChatSparkle24Regular className={sharedStyles.messageContainerIcon} />
              <p className={sharedStyles.messagesContainerTitle}>Ask QCounsel</p>
              <div className={sharedStyles.spacer}></div>
              {isChatVisible ? (
                <ChevronDown16Filled
                  className={sharedStyles.messagesContainerSwitch}
                  onClick={() => {
                    setIsChatVisible(false);
                    setIsChatOpen(false);
                  }}
                />
              ) : (
                <ChevronUp16Filled
                  className={sharedStyles.messagesContainerSwitch}
                  onClick={() => setIsChatVisible(true)}
                />
              )}
            </div>
            <Input
              className={sharedStyles.messageInput}
              placeholder={'Ask QCounsel anything'}
              onChange={onChange}
              onKeyDown={onKeyDown}
              value={value}
              appearance="outline"
              contentAfter={
                <div className={sharedStyles.messageInputButtonsContainer}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <Button
                    appearance="transparent"
                    icon={<Attach24Filled />}
                    size="medium"
                    onClick={handleUploadClick}
                  />
                  <Button
                    appearance="transparent"
                    icon={<Send24Filled />}
                    size="medium"
                    onClick={onMessageSend}
                  />
                </div>
              }
            />
          </div>
        </div>
      )}

      {/* Show spinner when loading */}
      {loading && <Spinner />}
      {failure && (
        <div className={sharedStyles.failureMessage}>
          Failed to process the file. Please try again.
        </div>
      )}
    </div>
  );
};
