import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  makeStyles,
  shorthands,
} from '@fluentui/react-components';
import {
  DocumentBriefcase24Regular,
  ChevronDown24Regular,
  Attach24Regular,
  Dismiss24Regular,
  ChevronUp24Regular,
} from '@fluentui/react-icons';

import { EmailAttachment } from '../../../model/openEmail';
import { useComposeModeContext } from '../../../provider/ComposeModeSocketProvider';

import { tokenHelper } from '../../../helper/tokenHelper';
import Spinner from './Spinner';

export const ComposePage: React.FC = () => {
  const styles = useStyles();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isSendLoading, setIsSendLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [temporarySelectedFiles, setTemporarySelectedFiles] = useState<
    string[]
  >([]);
  const [isFileAdded, setIsFileAdded] = useState<boolean>(false);
  const {
    sendUpdateDocumentAttachmentRequest,
    emailAttachmentList,
    sendGetReplyRequest,
    replyText,
    isSocketReady,
    isAttachmentList,
  } = useComposeModeContext();

  const sendGetReplySummaryRequest = () => {
    sendGetReplyRequest();
    setIsSendLoading(true);
  };

  useEffect(() => {
    tokenHelper.getAccessToken(token => {
      setAccessToken(token);
    });
  }, []);

  useEffect(() => {
    if (replyText) {
      setIsSendLoading(false);
      const processedReplyText = replyText.replace(/^Subject:.*$/m, '').trim();
      const formattedReplyText = processedReplyText
        .replace(/\n/g, '<br>')
        .replace(/ \*\*(.*?)\*\*\:/g, ' <strong>$1:</strong>');

      Office.context.mailbox.item.body.setAsync(
        formattedReplyText,
        { coercionType: Office.CoercionType.Html },
        function (asyncResult) {
          if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            console.error(
              'Failed to insert reply text:',
              asyncResult.error.message
            );
          } else {
            console.log('Reply text inserted successfully.');
          }
        }
      );
    }
  }, [replyText]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleFileSelect = (file: string) => {
    setTemporarySelectedFiles(prev =>
      prev.includes(file) ? prev.filter(f => f !== file) : [...prev, file]
    );
  };

  const handleAddFiles = () => {
    setSelectedFiles(prev => [
      ...prev,
      ...temporarySelectedFiles.filter(file => !prev.includes(file)),
    ]);
    setTemporarySelectedFiles([]);
    setIsDropdownOpen(false);
    setIsFileAdded(!isFileAdded);
  };

  const handleRemoveFile = (file: string) => {
    setSelectedFiles(prev => prev.filter(f => f !== file));
    if (selectedFiles.length === 1) {
      setIsFileAdded(false);
    }
  };

  const parseDate = (dateString: string): Date | null => {
    const [day, month, year] = dateString.split('/');

    if (!day || !month || !year) {
      console.error('Invalid Date Format:', dateString);
      return null;
    }

    const formattedDateString = `${year}-${month}-${day}`;
    const date = new Date(formattedDateString);

    if (isNaN(date.getTime())) {
      console.error('Invalid Date:', dateString);
      return null;
    }

    return date;
  };

  return (
    <div className={styles.container}>
      <div className={styles.slideButton}>
        <div className={styles.button}>
          <DocumentBriefcase24Regular />
        </div>
        <div className={styles.text}>
          <p>Draft Reply</p>
        </div>
      </div>

      <Button
        appearance="outline"
        onClick={toggleDropdown}
        className={styles.dropdownButton}
      >
        <div className={styles.dropdownRemove}>
          {isDropdownOpen ? <ChevronUp24Regular /> : <ChevronDown24Regular />}
        </div>
        <p>Attach Document</p>
      </Button>

      {isDropdownOpen && (
        <>
          <div className={styles.dropdownContent}>
            <p>Select Files</p>
            {isAttachmentList ? (
              <>
                {[...emailAttachmentList]
                  .sort((a, b) => {
                    const dateA = parseDate(a.date);
                    const dateB = parseDate(b.date);

                    if (!dateA || !dateB) return 0;

                    return dateB.getTime() - dateA.getTime();
                  })
                  .map((attachment: EmailAttachment) => (
                    <div key={attachment.name} className={styles.optionItem}>
                      <Checkbox
                        checked={temporarySelectedFiles.includes(
                          attachment.name
                        )}
                        onChange={() => handleFileSelect(attachment.name)}
                        label={attachment.name}
                        className={styles.fileOption}
                      />
                      <div className={styles.otherOption}>
                        <div>{attachment.date}</div>
                        <div>{attachment.path}</div>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <Spinner size={25} />
            )}
            <div className={styles.buttonGroup}>
              <Button appearance="primary" onClick={handleAddFiles}>
                Add
              </Button>
              <Button appearance="secondary" onClick={toggleDropdown}>
                Cancel
              </Button>
            </div>
          </div>
        </>
      )}
      {isFileAdded || selectedFiles.length > 0 ? (
        <div className={styles.selectedFilesContainer}>
          <div className={styles.tagContainer}>
            <div className={styles.fileIcon}>
              <Attach24Regular />
            </div>
            <div className={styles.tagsWrapper}>
              {selectedFiles.map((file, index) => (
                <div key={index} className={styles.tag}>
                  <span className={styles.tagText}>{file}</span>
                  <Dismiss24Regular
                    className={styles.removeIcon}
                    onClick={() => handleRemoveFile(file)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={styles.generateReply}>
        {selectedFiles.length > 0 ? (
          <Checkbox label="Attach file(s) in reply draft" />
        ) : (
          <></>
        )}
        {!isSocketReady || isSendLoading ? (
          <Button
            appearance="primary"
            className={styles.generateReplyButton}
            onClick={sendGetReplySummaryRequest}
          >
            <Spinner />
          </Button>
        ) : (
          <Button
            appearance="primary"
            className={styles.generateReplyButton}
            onClick={sendGetReplySummaryRequest}
          >
            Generate Reply Text
          </Button>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  dropdown: {
    marginBottom: '10px',
    overflowWrap: 'anywhere',
    width: '270px',
  },
  dropdownOption: {
    width: '230px',
    marginTop: '0px',
    paddingLeft: '0',
    marginLeft: '0',
    paddingRight: '0',
    marginRight: '0',
    overflowWrap: 'anywhere',
  },
  docContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  docName: {
    marginLeft: '10px',
    fontSize: '12px',
    color: '#787878',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...shorthands.padding('30px', '20px', '0px', '20px'),
    position: 'relative',
  },
  nextUIButton: {
    width: '100%',
    height: '50px',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'end',
    alignItems: 'center',
    ...shorthands.borderRadius('25px'),
    cursor: 'pointer',
  },
  slideButton: {
    width: '140px',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    ...shorthands.borderRadius('25px'),
  },
  mextButton: {
    width: '45px',
    height: '45px',
    ...shorthands.borderRadius('50%'),
    ...shorthands.margin('10px', '10px', '0', '5px'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    backgroundImage: 'linear-gradient(to top right, #0560ae, #010305)',
    zIndex: 1,
  },
  button: {
    width: '45px',
    height: '45px',
    ...shorthands.borderRadius('50%'),
    marginRight: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonBackground: {
    zIndex: 3,
    backgroundColor: 'black',
    width: '39px',
    height: '39px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...shorthands.borderRadius('50%'),
  },
  text: {
    fontSize: '14px',
    fontWeight: '700',
    color: '#333',
  },
  nextText: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#333',
    marginTop: '10px',
  },
  dropdownButton: {
    marginTop: '20px',
    width: '270px',
    height: '40px',
    position: 'relative',
  },
  dropdownRemove: {
    float: 'left',
    position: 'absolute',
    right: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '15px',
  },
  dropdownContent: {
    marginTop: '10px',
    ...shorthands.border('1px', 'solid', '#ccc'),
    ...shorthands.padding('10px'),
    width: '270px',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px 3px rgba(0, 2, 0, 0.2)',
  },
  optionItem: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'end',
    marginBottom: '8px',
  },
  fileOption: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  otherOption: {
    paddingLeft:'37px'    
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  selectedFilesContainer: {
    marginTop: '20px',
    width: '270px',
    ...shorthands.padding('10px'),
    ...shorthands.borderRadius('8px'),
  },
  tagContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  tagsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('8px'),
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#cdc8c8',
    ...shorthands.padding('8px'),
    ...shorthands.borderRadius('20px'),
    width: 'max-content',
    maxWidth: '230px',
  },
  tagText: {
    marginRight: '8px',
    fontSize: '12px',
    fontWeight: '400',
  },
  removeIcon: {
    fontSize: '12px',
    cursor: 'pointer',
    marginLeft: '10px',
  },
  fileIcon: {
    marginRight: '8px',
    marginTop: '7px',
  },
  generateReply: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
    width: '270px',
  },
  generateReplyButton: {
    width: '100%',
  },
  returnButton: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    cursor: 'pointer',
  },
});

export default ComposePage;
