import { makeStyles, shorthands } from '@fluentui/react-components';
import React from 'react';
import { Image } from '@fluentui/react-components'; // Assuming you're using Fluent UI Image component

type Props = {};

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
    maxWidth: '100%',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
  },
  fileTypeIcon: {
    width: '150px',
    height: 'auto',
    marginBottom: '20px',
  },
  message: {
    fontSize: '18px',
    marginBottom: '20px',
    paddingLeft: '25px',
    paddingRight: '25px',
    textAlign: 'center',
  },
  link: {
    color: '#04142d',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
});

const NoSeat: React.FC<Props> = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Image
        className={styles.fileTypeIcon}
        src={'assets/original.png'} // Ensure this path is correct relative to your public/assets folder
        alt="Company Logo"
      />
      <div className={styles.message}>
        Please contact{' '}
        <a href="mailto:sales@qanooni.ai" target="_blank" className={styles.link}>
          sales@qanooni.ai
        </a>{' '}
        or visit,{' '}
        <a href="https://marketplace.microsoft.com" target="_blank" className={styles.link}>
          Microsoft Marketplace 
        </a>{' '}
        to purchase a subscription.
      </div>
    </div>
  );
};

export default NoSeat;
