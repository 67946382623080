import { visit } from 'unist-util-visit';

export default function rehypeUnderline() {
  return (tree: any) => {
    visit(tree, 'element', (node: any) => {
      if (node.tagName === 'ins') {
        node.properties = { ...node.properties, style: 'text-decoration: underline;' };
      }
    });
  };
}
