import * as React from 'react';
import {
  makeStyles,
  mergeClasses,
  shorthands,
} from '@fluentui/react-components';

import { Summary, SummaryStatusEnum } from '../../../model/summary';
import { MarkdownRenderer } from '../misc/MarkdownRenderer';

interface SummaryContainerProps {
  currentMessageSummary: Summary;
}

export const SummaryContainer: React.FC<SummaryContainerProps> = ({
  currentMessageSummary,
}) => {
  const styles = useStyles();

  const summaryTextStyle =
    currentMessageSummary.status === SummaryStatusEnum.Completed
      ? styles.textSituationOverview
      : mergeClasses(styles.textSituationOverview, styles.flashingText);

  return (
    <div className={styles.container}>
      <div className={styles.sectionContainer}>
        <div className={summaryTextStyle}>
          <MarkdownRenderer markdown={currentMessageSummary.summaryParagraph} />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    ...shorthands.padding(0, '12px'),
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  sectionTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  senderName: {
    marginLeft: '8px',
  },
  sectionTitleIcon: {
    width: '18px',
    marginRight: '4px',
    color: '#8F8F8F',
  },
  attachmentSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textStrong: {
    fontSize: '16px',
    fontWeight: '700',
    color: '#2A2A2A',
  },
  textHighlighted: {
    fontSize: '14px',
    color: '#0387F5',
  },
  textGreyedOut: {
    fontSize: '12px',
    color: '#8F8F8F',
  },
  textSituationOverview: {
    fontSize: '12px',
    color: '#2A2A2A',
  },
  flashingText: {
    animationName: {
      from: { opacity: 1 },
      to: { opacity: 0 },
    },
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    animationDirection: 'alternate',
  },
});
