import React, { createContext, useContext, useEffect, useState } from 'react';

import { ActionEnum, BaseResponse, StatusEnum } from '../model/base';
import { EmailAttachment } from '../model/openEmail';
import {
  GetUnsentDocumentAttachmentsRequest,
  GetUnsentDocumentAttachmentsResponse,
  UpdateDocumentAttachmentRequest,
  UpdateDocumentAttachmentResponse,
  GetReplyResponse,
  getReplyRequest,
} from '../model/attachment';

import {
  isGetUnsentDocumentAttachmentsResponse,
  isUpdateDocumentAttachmentResponse,
  isGetReplyResponse,
} from '../helper/misc';
import { tokenHelper } from '../helper/tokenHelper'; // Import the TokenHelper
import { seatSocketMessages } from '../model/seat';
import { useAppContext } from './appProvider';

const ComposeModeContext = createContext({
  emailAttachmentList: [] as EmailAttachment[],
  replyText: null,
  isSocketReady: false,
  isAttachmentList: false,
  sendUpdateDocumentAttachmentRequest: (
    _emailAttachment: EmailAttachment
  ) => {},
  sendGetReplyRequest: () => {},
});

export const useComposeModeContext = () => useContext(ComposeModeContext);

interface ComposeModeSocketProviderProps {
  children: React.ReactNode;
}

const getToken = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    tokenHelper.getAccessToken(token => {
      if (token) {
        resolve(token);
      } else {
        reject('Failed to get token');
      }
    });
  });
};

export const ComposeModeSocketProvider: React.FC<
  ComposeModeSocketProviderProps
> = ({ children }) => {
  const [isSocketReady, setSocketReady] = useState(false);
  const socketRef = React.useRef<WebSocket | null>(null);
  const [emailAttachmentList, setEmailAttachmentList] = useState<
    EmailAttachment[]
  >([]);
  const [replyText, setReplyText] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null); // State to store the token
  const { seatStatus, setSeatStatus } = useAppContext();
  const [isAttachmentList, setIsAttachmentList] = useState<boolean | null>(
    false
  );
  useEffect(() => {
    const fetchToken = async () => {
      const token = await getToken();
      setToken(token);
    };
    fetchToken();
  }, []);

  useEffect(() => {
    if (token && !socketRef.current) {
      const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
      console.log('WebSocket URL:', SOCKET_URL);
      const socketUrlWithToken = `${SOCKET_URL}?token=${token}`;
      try {
        socketRef.current = new WebSocket(socketUrlWithToken);
      } catch (e) {
        console.log(e);
        return () => {};
      }

      socketRef.current.onopen = () => {
        console.log('WebSocket connection established for compose mode');
        setIsAttachmentList(true);
        sendAttachmentListRequest();
        setSocketReady(true);
      };

      socketRef.current.onerror = error => {
        console.error('WebSocket error:', error);
      };

      socketRef.current.onclose = event => {
        console.log('WebSocket connection closed:', event);
        setSocketReady(false);
      };

      socketRef.current.onmessage = (event: MessageEvent<string>) => {
        if (
          event.data == seatSocketMessages.no_seat ||
          event.data == seatSocketMessages.no_tandc
        ) {
          setSeatStatus(event.data);
          return;
        }

        const response: BaseResponse = JSON.parse(event.data);

        console.log('WebSocket message received:', response);

        if (response.status === StatusEnum.Error) {
          console.error('Error from server:', response.errorMessage);
        }

        if (response.status === StatusEnum.Success) {
          if (isGetUnsentDocumentAttachmentsResponse(response)) {
            const attachments =
              response as GetUnsentDocumentAttachmentsResponse;

            const attachmentDocuments = attachments.unsent_documents;

            // Reset the list in case we're re-fetching the attachments
            setEmailAttachmentList([]);

            attachmentDocuments.forEach(attachment => {
              const newEmailAttachment: EmailAttachment = {
                originalId: attachment.id,
                name: attachment.name,
                path: attachment.path,
                type: attachment.type,
                date: attachment.date,
                modifiedDate: attachment.modifiedDate,
              };

              setEmailAttachmentList(prevList => [
                ...prevList,
                newEmailAttachment,
              ]);

              setIsAttachmentList(true);
            });
          }

          // Handle marking document as sent on BE
          if (isUpdateDocumentAttachmentResponse(response)) {
            const updatedAttachment =
              response as UpdateDocumentAttachmentResponse;
            console.log('Updated attachment:', updatedAttachment);

            // Re-fetch the list of available attachments
            sendAttachmentListRequest();
          }

          if (isGetReplyResponse(response)) {
            if (response) {
              const result = response as GetReplyResponse;
              setReplyText(result.reply_text);
            }
          }
        }
      };
    }

    return () => {
      socketRef.current?.close();
    };
  }, [token]);

  const sendAttachmentListRequest = (): void => {
    const item = Office.context.mailbox.item;
    const parentMessageId = item.conversationId;

    const attachmentRequest: GetUnsentDocumentAttachmentsRequest = {
      action: ActionEnum.GetUnsentDocumentAttachmentsRequest,
      email_id: parentMessageId,
    };
    console.log('Sending an attachment list request:', attachmentRequest);
    socketRef.current.send(JSON.stringify(attachmentRequest));
  };

  const sendUpdateDocumentAttachmentRequest = (
    emailAttachment: EmailAttachment
  ): void => {
    if (socketRef.current?.readyState === WebSocket.OPEN) {
      const request: UpdateDocumentAttachmentRequest = {
        action: ActionEnum.UpdateDocumentAttachmentRequest,
        document_attachment: {
          id: emailAttachment.originalId,
          name: emailAttachment.name,
          path: emailAttachment.path,
          type: emailAttachment.type,
          send: false,
          date: emailAttachment.date,
          modifiedDate: emailAttachment.modifiedDate,
        },
      };

      console.log('Sending a document update request:', request);
      socketRef.current.send(JSON.stringify(request));
    }
  };

  const sendGetReplyRequest = () => {
    const item = Office.context.mailbox.item;
    const parentMessageId = item.conversationId;

    const request: getReplyRequest = {
      action: ActionEnum.GetReplyRequest,
      conversation_id: parentMessageId,
    };
    socketRef.current.send(JSON.stringify(request));
  };

  return (
    <ComposeModeContext.Provider
      value={{
        emailAttachmentList,
        replyText,
        isSocketReady,
        isAttachmentList,
        sendUpdateDocumentAttachmentRequest,
        sendGetReplyRequest,
      }}
    >
      {children}
    </ComposeModeContext.Provider>
  );
};
