import { BaseResponse } from '../model/base';

export enum DocumentType {
    Word = 'Word',
    PDF = 'PDF',
    Excel = 'Excel',
    PowerPoint = 'PowerPoint',
}
export interface RelevantDocument {
    name: string;
    path: string;
    type: DocumentType;
    dateCreated: string;
}

export interface RelevantDocumentResponse extends BaseResponse {
    documents: RelevantDocument[];
}