import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { UserFeedbackRequest } from '../../../model/feedback';
import { ActionEnum } from '../../../model/base';
import { useMailboxContent } from '../../../provider/MailboxContentProvider';
import { MessageRole } from '../../../model/chat';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import * as pdfjsLib from 'pdfjs-dist';
import { useAppContext } from '../../../provider/appProvider';
import { QCounsel } from 'qanoonisharedpackage';

interface ChatContainerProps {}
export const ChatContainer: React.FC<ChatContainerProps> = ({}) => {
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const [failure, setfailure] = useState(false);
  const {
    addAttachMessage,
    isSocketReady,
    userOfflineProcess,
    featureRefId,
    traceId,
    socketRef,
    chatMessages,
    sendChatMessage,
    removeChatMessage,
  } = useMailboxContent();
  const { isStreaming, setIsStreaming } = useAppContext();

  const [isRegenerating, setIsRegenerating] = useState(false);

  const handleRegenerate = async (messageId: string) => {
    console.log('regenerate pressed');
    setIsRegenerating(true);

    // Find the index of the message to regenerate
    const messageIndex = chatMessages.findIndex(msg => msg.id === messageId);
    if (messageIndex === -1) {
      console.error('Message not found');
      setIsRegenerating(false);
      return;
    }

    // Remove the message and all subsequent messages
    for (let i = chatMessages.length - 1; i >= messageIndex; i--) {
      removeChatMessage(chatMessages[i].id);
    }

    // Get the last user message before the removed message
    let lastUserMessage = '';
    for (let i = messageIndex - 1; i >= 0; i--) {
      if (chatMessages[i].role === MessageRole.User) {
        lastUserMessage = chatMessages[i].message;
        break;
      }
    }

    // Regenerate the response
    try {
      await sendChatMessage(
        {
          id: uuidv4(),
          message: lastUserMessage,
          role: MessageRole.User,
          isChunk: false,
          attached: null,
        },
        false
      );
    } catch (error) {
      console.error('Error regenerating message:', error);
    }

    setIsRegenerating(false);
  };

  const sendFeedbackGet = (
    feedbackType: 'thumbs_up' | 'thumbs_down',
    feedbackContent: string
  ) => {
    const request: UserFeedbackRequest = {
      action: ActionEnum.UserFeedbackRequest,
      feature_type: 'QCouncel chat',
      feature_ref_id: featureRefId,
      feedback: feedbackType,
      trace_id: traceId,
      note: feedbackContent,
    };

    console.log('Sending feedback', request);
    if (socketRef && socketRef.readyState === WebSocket.OPEN) {
      socketRef.send(JSON.stringify(request));
    } else {
      console.error('WebSocket is not open. Unable to send feedback.');
    }
    // Close the modal after sending feedback
    console.log('Sent FeedbackGetRequest to server', request);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      setLoading(true); // Show spinner when processing starts
      setfailure(false);

      try {
        if (fileExtension === 'txt') {
          // Handle .txt files directly
          const text = await selectedFile.text();
          console.log('attached text:', text);
          setFileContent(text);
        } else if (fileExtension === 'docx') {
          // Handle .docx files
          const arrayBuffer = await selectedFile.arrayBuffer();
          const zip = new PizZip(arrayBuffer);
          const doc = new Docxtemplater(zip);
          const text = doc.getFullText();
          console.log('attached text:', text);
          setFileContent(text);
        } else if (fileExtension === 'pdf') {
          // Handle .pdf files
          console.log('Processing PDF file');
          const arrayBuffer = await selectedFile.arrayBuffer();
          console.log('arrayBuffer:', arrayBuffer);

          // Convert ArrayBuffer to Uint8Array
          const uint8Array = new Uint8Array(arrayBuffer);

          // Load PDF with pdfjsLib
          const pdf = await pdfjsLib.getDocument({
            data: uint8Array,
            worker: null,
          }).promise;
          console.log('pdf:', pdf);
          let text = '';

          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const textContent = await page.getTextContent();
            textContent.items.forEach((item: any) => {
              if ('str' in item) {
                text += item.str + ' ';
              }
            });
          }
          console.log('attached text:', text);
          setFileContent(text);
        } else {
          // Handle unsupported file extensions
          console.log(`Unsupported file type: ${fileExtension}`);
          addAttachMessage({
            id: uuidv4(),
            message: `Failed to attach ${selectedFile.name}. Supported file types are PDF, DOCX, and TXT`,
            role: MessageRole.Attach,
            isChunk: false,
            attached: null,
          });
          return; // Exit the function if the file type is unsupported
        }
        addAttachMessage({
          id: uuidv4(),
          message: ` ${selectedFile.name}`,
          role: MessageRole.Attach,
          isChunk: false,
          attached: null,
        });
        console.log('attach message sent');
      } catch (error) {
        console.error('Error processing file:', error);
        setfailure(true);
        setLoading(false);
      } finally {
        setLoading(false); // Hide spinner when processing completes
      }
    }
  };

  const onMessageSend = (value: string) => {
    const messageData = {
      message: value,
      attached: fileContent ? fileContent : null,
    };
    console.log('Sending a chat message:', messageData);
    sendChatMessage(
      {
        id: uuidv4(),
        message: value,
        role: MessageRole.User,
        isChunk: false,
        attached: fileContent ? fileContent : null,
      },
      true
    );
    localStorage.setItem('isStopStreaming', 'false');
  };

  useEffect(() => {
    console.log(isStreaming, localStorage.getItem('isStopStreaming'));
  }, [localStorage, isStreaming]);

  return (
    <div id="chatCnt">
      <QCounsel
        isSocketReady={isSocketReady}
        userOfflineProcess={userOfflineProcess}
        chatMessages={chatMessages}
        sendFeedbackGet={sendFeedbackGet}
        onMessageSend={onMessageSend}
        handleFileChange={handleFileChange}
        loading={loading}
        failure={failure}
        setFileContent={setFileContent}
        handleRegenerate={handleRegenerate}
        isStreaming={isStreaming}
        stopResponding={() => {
          localStorage.setItem('isStopStreaming', 'true');
          setIsStreaming(false);
        }}
        positionTop="0px"
      />
    </div>
  );
};
